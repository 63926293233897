import * as React from 'react';
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { logout, useAuth, authFetch } from './auth'

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  navlogolg: {
      color: '#9BCDB0',
      width: '100%',
      marginRight: '.5em',
  },
  navlogomd: {
    color: '#9BCDB0',
    width: '70px',
    marginRight: '.5em',
},
})

const pages = [{name: 'Products', code: '/'}];
const settings = [{name: 'Log In', code: '/auth/login'},{name: 'Register', code: '/auth/signup'}]
const accountSettings = [{name: 'Profile', code: '/auth/profile'},{name: 'Logout', code: '/'}]


const ResponsiveAppBar = ({badgeSwitch,update}) => {
  const classes = useStyles()
  const [logged]=useAuth()
  const [anchorElUserSm, setAnchorElUserSm] = React.useState(null);
  const [anchorElUserLg, setAnchorElUserLg] = React.useState(null);
  const [anchorElNonUserSm, setAnchorElNonUserSm] = React.useState(null);
  const [anchorElNonUserLg, setAnchorElNonUserLg] = React.useState(null);
  const [companyHistory, setCompanyHistory] = React.useState(false);
  const [influencerPhoto, setInfluencerPhoto] = React.useState('/default.jpg');
  const [personalize, setPersonalize] = React.useState({
    "picture_location": "/brackettinnovations/logo_light.png",
    "cart_number": 0,
    "influencer_number": 1
  });
  const [badgeImage, setBadgeImage] = React.useState('/default.jpg');
  const [showBadges, setShowBadges] = React.useState(false);
  const [featuredCompany,setFeaturedCompany]=React.useState(
    {
        "id":1,
        "name_code":"brackett_innovations",
        "name_view":"Brackett Innovations",
        "primary_color":'#366C4D',
    }
);


  React.useEffect(()=> {
    const requestOptions = {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
      }
    }
    const params = new URLSearchParams(window.location.search)
    const q= params.get('featured_company');
    {q ? localStorage.setItem('Company', q.toString()) : <></> }
    let storedValue = localStorage.getItem('Company');
    
    if (storedValue) {
      fetch(`/api/company/bar/id/${localStorage.getItem('Company')}`, requestOptions)
        .then(function(res) {
          if (res.status === 200) {
              return res.json();
          } else {
              throw new Error()
          }
        })
        .then(data => {
          setFeaturedCompany(data)
          setCompanyHistory(true)
        })
        .catch(err=>console.log(err))
    }
  },[]);

  React.useEffect(()=> {
    const requestOptions = {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
      }
    }
    if (logged) {
      
      loggedInStats()
      loggedInBadge()
  
    } else {
      setPersonalize({
        "picture_location": "/brackettinnovations/logo_light.png",
        "cart_number": 0,
        "influencer_number": 1
      })
      setShowBadges(false)
    }
    const storedValue = localStorage.getItem('Influencer');

    if (!storedValue) {
      localStorage.setItem('Influencer', '1');
      getInfluencerImage(1)
    } else {
      getInfluencerImage(storedValue)
    }
      
  },[logged,update]);

  const loggedInBadge = () => {
    const requestOptions = {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
      }
    }

    authFetch(`/api/badge/`, requestOptions)
      .then(function(res) {
        if (res.status === 200) {
            return res.json();
        } else {
            throw new Error()
        }
      })
      .then(data => {
        setShowBadges(data.showBadge)
        setBadgeImage(data.badge_image)
      })
      .catch(err=>console.log(err))    
  }

  const getInfluencerImage = (id) => {
    const requestOptions = {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
      }
    }

    fetch(`/api/influencer/${id}`, requestOptions)
      .then(function(res) {
        if (res.status === 200) {
            return res.json();
        } else {
            throw new Error()
        }
      })
      .then(data => {
        setInfluencerPhoto(data.picture_location)
      })
      .catch(err=>console.log(err))
  }

  const loggedInStats = () => {
    const requestOptions = {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
      }
    }

    authFetch('/api/auth/stats', requestOptions)
      .then(function(res) {
        if (res.status === 200) {
            return res.json();
        } else {
            throw new Error()
        }
      })
      .then(data => {
        setPersonalize(data)
      })
      .catch(err=>console.log(err))
    
  }

  const isLogout = (action) => {
    console.log('clicked', action)
    if (action==="Logout") {
      logout()
    }
  };

  const handleOpenUserMenuSm = (event) => {
    setAnchorElUserSm(event.currentTarget);
  };

  const handleCloseUserMenuSm = () => {
    setAnchorElUserSm(null);
  };

  const handleOpenUserMenuLg = (event) => {
    setAnchorElUserLg(event.currentTarget);
  };

  const handleCloseUserMenuLg = () => {
    setAnchorElUserLg(null);
  };

  const handleOpenNonUserMenuSm = (event) => {
    setAnchorElNonUserSm(event.currentTarget);
  };

  const handleCloseNonUserMenuSm = () => {
    setAnchorElNonUserSm(null);
  };

  const handleOpenNonUserMenuLg = (event) => {
    setAnchorElNonUserLg(event.currentTarget);
  };

  const handleCloseNonUserMenuLg = () => {
    setAnchorElNonUserLg(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <img src={process.env.PUBLIC_URL + '/brackettinnovations/logo_dark.png'} className={classes.navlogomd} alt="[i]"/> 
          </Box>
          <Box sx={{
                display: { xs: 'none', md: 'inline-block' },
              }}>
            <Typography
              variant="h6"
              style={{ wordWrap: "break-word" }}
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.1.5rem',
                color: 'inherit',
                textDecoration: 'none',
                lineHeight: '1.2',
              }}
            >
              Brackett
            </Typography>
          
            <Typography
              variant="h6"
              style={{ wordWrap: "break-word" }}
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.1.5rem',
                color: 'inherit',
                textDecoration: 'none',
                lineHeight: '1.2',
              }}
            >
              Innovations
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {pages.map(({name, code}) => (
            <Link key={name} to={code} style={{ textDecoration: 'none' }}>
              <Button
                key={name}
                variant="contained"
                sx={{ 
                  my: .5, 
                  color: '#E3F1E9', 
                  display: 'block',
                  marginRight: 'auto',
                  marginLeft: 'auto', 
                  boxShadow: '-30px 0px 16px -10px #9BCDB0,30px 0px 16px -10px #9BCDB0',
                  '&:hover': {
                    boxShadow: '-30px 0px 16px -10px #E3F1E9,30px 0px 16px -10px #E3F1E9',
                    backgroundColor: 'black',
                  },
                }}
              >
                {name}
              </Button>
            </Link>
          ))}
            <Link to={`/about`} style={{ textDecoration: 'none', paddingLeft: '15px' }}>
              <Button
                variant="contained"
                sx={{ 
                  my: .5, 
                  color: '#E3F1E9', 
                  display: 'block',
                  marginRight: 'auto',
                  marginLeft: 'auto', 
                  boxShadow: `-30px 0px 16px -10px #9BCDB0,30px 0px 16px -10px #9BCDB0`,
                  '&:hover': {
                    boxShadow: `-30px 0px 16px -10px #E3F1E9,30px 0px 16px -10px #E3F1E9`,
                    backgroundColor: 'black',
                  },
                }}
              >
                About Us
              </Button>
            </Link>
            {companyHistory?
              <Link to={`/company/${featuredCompany.name_code}`} style={{ textDecoration: 'none', paddingLeft: '15px' }}>
                <Button
                  variant="contained"
                  sx={{ 
                    my: .5, 
                    color: '#E3F1E9', 
                    display: 'block',
                    marginRight: 'auto',
                    marginLeft: 'auto', 
                    boxShadow: `-30px 0px 16px -10px ${featuredCompany.primary_color},30px 0px 16px -10px ${featuredCompany.primary_color}`,
                    '&:hover': {
                      boxShadow: `-30px 0px 16px -10px ${featuredCompany.secondary_color},30px 0px 16px -10px ${featuredCompany.secondary_color}`,
                      backgroundColor: 'black',
                    },
                  }}
                >
                  {featuredCompany.name_view}
                </Button>
              </Link>
            :
              <></>
            }
          </Box>            
       
          <Box sx={{display: { xs: 'none', md: 'flex' }}}>
            {showBadges?
                <Box sx={{ marginRight: '5px', marginTop: '7px'}}>
                  <Avatar 
                      src={process.env.PUBLIC_URL + '/img' + badgeImage}
                      size="large"
                      onClick={badgeSwitch}
                  />
                </Box>
              :
              <div>
              </div>
            }
            <Tooltip title="Influencers">
              <Link to='/influencer' style={{ textDecoration: 'none' }}>
                <IconButton color="inherit">
                  <Badge badgeContent={personalize.influencer_number} color="secondary">
                    <Avatar 
                      alt="Influencers"
                      src={process.env.PUBLIC_URL + '/img' + influencerPhoto}
                      size="large"
                    />
                  </Badge>
                </IconButton>
              </Link>
            </Tooltip>
            {logged?
                <Box sx={{ flexGrow: 0, marginRight: '5px', display: { xs: 'none', md: 'flex' } }}>
                    <Tooltip title="Cart">
                      <Link to='/cart' style={{ textDecoration: 'none' }}>
                        <IconButton color="inherit">
                          <Badge badgeContent={personalize.cart_number} color="secondary">
                            <Avatar alt="Cart">
                              <ShoppingCartIcon aria-label="Cart" color="secondary"/>
                            </Avatar>
                          </Badge>
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </Box>
                
              :
                <div>
                </div>
            }
          </Box>  
          <Box sx={{ flexGrow: 0, display: { xs: 'none',md: 'inline-block' } }}>
            <Tooltip title="Account">
              {logged?
                  <IconButton id="menu-appbarmd" onClick={handleOpenUserMenuSm} sx={{ p: 0 }}>
                    <Avatar 
                      alt="Account" 
                      src={process.env.PUBLIC_URL + '/img' + personalize.picture_location}
                      size="large"
                    />
                  </IconButton>
                :
                  <IconButton id="menu-appbar-md" onClick={handleOpenNonUserMenuSm} sx={{ p: 0 }}>
                    <Avatar alt="?">
                      <LockRoundedIcon />
                    </Avatar>
                  </IconButton>
              }
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar-md"
              anchorEl={anchorElNonUserSm}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right' ,
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right' ,
              }}
              open={Boolean(anchorElNonUserSm)}
              onClose={handleCloseNonUserMenuSm}
            >
              {settings.map(({name, code}) => (
                  <Link key={name} to={code} style={{ textDecoration: 'inherit' }}>
                    <MenuItem 
                      key={name}
                      value={code}
                      onClick={(event)=>{handleCloseNonUserMenuSm(event)}}
                    >
                      <Typography textAlign="center">{name}</Typography>
                    </MenuItem>
                  </Link>
                ))}
            </Menu>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar-md"
              anchorEl={anchorElUserSm}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right' ,
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right' ,
              }}
              open={Boolean(anchorElUserSm)}
              onClose={handleCloseUserMenuSm}
            >
              {accountSettings.map(({name, code}) => (
                  <Link key={name} to={code} style={{ textDecoration: 'none' }}>
                    <MenuItem 
                      key={name}
                      value={code}
                      onClick={(event)=>{handleCloseUserMenuSm(event); isLogout(name)}}
                    >
                      <Typography textAlign="center">{name}</Typography>
                    </MenuItem>
                  </Link>
                ))}
            </Menu>
          </Box>
          
          <Box 
            sx={{
              display: { xs: 'flex', md: 'none' }, 
              mr: 1,
              flexGrow: 1,
              position: 'relative',
              textAlign: 'center',
             }}
          >
            <Box>
              <img src={process.env.PUBLIC_URL + '/brackettinnovations/logo_dark.png'} className={classes.navlogolg} alt="[i]" />
            </Box>
            <Box 
              sx={{ display: { xs: 'block', md: 'none' },
              position: 'absolute',
              marginTop: '9%',
              top: 0,
              left: 1,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Tooltip title="Account">
                {logged?
                  <IconButton onClick={handleOpenUserMenuLg} sx={{ p: 0 }}>
                    <Avatar 
                        src={process.env.PUBLIC_URL + '/img' + personalize.picture_location}
                        sx={{ width: 60, height: 60 }}
                      />
                  </IconButton>
                  :
                  <IconButton onClick={handleOpenNonUserMenuLg} sx={{ p: 0 }}>
                    <Avatar variant='circular'>
                      <LockRoundedIcon 
                        size="large"
                        aria-label="Account"
                        color="inherit"
                      />
                    </Avatar>
                  </IconButton>
                }
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElNonUserLg}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={Boolean(anchorElNonUserLg)}
                onClose={handleCloseNonUserMenuLg}
              >
                {settings.map(({name, code}) => (
                    <Link key={name} to={code} style={{ textDecoration: 'none' }}>
                      <MenuItem 
                        key={name}
                        value={code}
                        onClick={(event)=>{handleCloseNonUserMenuLg(event)}}
                      >
                        <Typography textAlign="center">{name}</Typography>
                      </MenuItem>
                    </Link>
                  ))}
              </Menu>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUserLg}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={Boolean(anchorElUserLg)}
                onClose={handleCloseUserMenuLg}
              >
                {accountSettings.map(({name, code}) => (
                    <Link key={name} to={code} style={{ textDecoration: 'none' }}>
                      <MenuItem 
                        key={name}
                        value={code}
                        onClick={(event)=>{handleCloseUserMenuLg(event); isLogout(name)}}
                      >
                        <Typography textAlign="center">{name}</Typography>
                      </MenuItem>
                    </Link>
                  ))}
              </Menu>
            </Box>
            <Box 
              sx={{ display: { xs: 'block', md: 'none' }, 
              position: 'absolute',
              marginTop: '25%',
              top: 0,
              left: 1,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}>
            <Grid 
              container
              sx={{
              display: { xs: 'flex', md: 'none' }, 
              mr: 1,
              flexGrow: 1,
              position: 'relative',
              textAlign: 'center',
              }}
              // direction="row-reverse"
              justifycontent="center"
              alignItems="flex-start"
            >
              {pages.map(({name, code}) => (
                <Grid item xs={12}>
                  <Link key={name} to={code} style={{ textDecoration: 'none' }}>
                    <Button
                      key={name}
                      variant="contained"
                      sx={{ 
                        my: .5, 
                        color: '#E3F1E9', 
                        display: 'block',
                        marginRight: 'auto',
                        marginLeft: 'auto', 
                        boxShadow: '-30px 0px 16px -10px #9BCDB0,30px 0px 16px -10px #9BCDB0',
                        '&:hover': {
                          boxShadow: '-30px 0px 16px -10px #E3F1E9,30px 0px 16px -10px #E3F1E9',
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      {name}
                    </Button>
                  </Link>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Link to={`/about`} style={{ textDecoration: 'none'}}>
                  <Button
                    variant="contained"
                    sx={{ 
                      my: .5, 
                      color: '#E3F1E9', 
                      display: 'block',
                      marginRight: 'auto',
                      marginLeft: 'auto', 
                      boxShadow: `-30px 0px 16px -10px #9BCDB0,30px 0px 16px -10px #9BCDB0`,
                      '&:hover': {
                        boxShadow: `-30px 0px 16px -10px #E3F1E9,30px 0px 16px -10px #E3F1E9`,
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    About Us
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12}>
                {companyHistory?
                    <Link to={`/company/${featuredCompany.name_code}`} style={{ textDecoration: 'none' }}>
                      <Button
                        variant="contained"
                        sx={{ 
                          my: .5, 
                          color: '#E3F1E9', 
                          display: 'block',
                          marginRight: 'auto',
                          marginLeft: 'auto', 
                          boxShadow: `-30px 0px 16px -10px ${featuredCompany.primary_color},30px 0px 16px -10px ${featuredCompany.primary_color}`,
                          '&:hover': {
                            boxShadow: `-30px 0px 16px -10px ${featuredCompany.secondary_color},30px 0px 16px -10px ${featuredCompany.secondary_color}`,
                            backgroundColor: 'black',
                          },
                        }}
                      >
                        {featuredCompany.name_view}
                      </Button>
                    </Link>
                :
                    <></>
                }
              </Grid>
               <Grid item xs={3} marginTop='1em'></Grid>

              <Grid item xs={2} marginTop='1em'>
                {showBadges ?
                  <Tooltip title="Badges">
                    <IconButton color="inherit">
                        <Avatar 
                          alt="*"
                          src={process.env.PUBLIC_URL + '/img' + badgeImage}
                          onClick={badgeSwitch}
                          size="large"
                        >
                        </Avatar>
                    </IconButton>
                  </Tooltip>
                :
                  <div></div>
                }
              </Grid>
              <Grid item xs={2} marginTop='1em'>
                <Tooltip title="Influencers">
                  <Link to='/influencer' style={{ textDecoration: 'none' }}>
                    <IconButton color="inherit">
                      <Badge 
                        badgeContent={personalize.influencer_number} 
                        color="secondary"
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <Avatar 
                          alt="Influencer"
                          src={process.env.PUBLIC_URL + '/img' + influencerPhoto}
                          size="large"
                        />
                      </Badge>
                    </IconButton>
                  </Link>
                </Tooltip>
              </Grid> 
              
              <Grid item xs={2} marginTop='1em'> 
                {logged? 
                    <IconButton color="inherit">
                          
                      <Tooltip title="Cart">
                        <Link to='/cart' style={{ textDecoration: 'none' }}>
                          <Badge badgeContent={personalize.cart_number} color="secondary">
                            <Avatar 
                              alt="Cart"
                            >
                              <ShoppingCartIcon aria-label="Cart" color="secondary"/>
                            </Avatar>
                          </Badge>
                        </Link>
                      </Tooltip>
                    </IconButton>
                  :
                    <div></div>
                  }  
                </Grid>
                
                <Grid item xs={3} marginTop='1em'></Grid>  
              </Grid>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
