import React from 'react'
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Rating } from '@mui/material';

const useStyles = makeStyles({
        name: {
            color: (props) => props.primary_color,
            width: '100%',
            marginRight: '.5em',
        },
        navlogosm: {
          color: '#9BCDB0',
          width: '70px',
          marginRight: '.5em',
      },
      })


// const Company = ({name_view,logo,description,title,cover,primary_color,secondary_color}) => {
const Company = (props) => {
    const {name_view,price,logo,description,title,cover,primary_color,secondary_color,review,review_total} = props
    const logo_img_path=process.env.PUBLIC_URL + '/img' +  props.logo
    const cover_img_path=process.env.PUBLIC_URL + '/img' + props.cover
    
    const classes=useStyles(props)
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
          <Card  sx={{ maxWidth: '500px', p: '5px', m: '10px' }}>
          <CardHeader
            avatar={
              <Avatar alt={props.name_view}
              src={logo_img_path}
              >
                {props.name_view}
              </Avatar>
            }
            title={props.title}
            subheader={props.name_view}
          />
          <CardMedia
            component="img"
            height="194"
            image={cover_img_path}
            alt={props.name_view}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Typography variant="h4" className={classes.name}>
              ${props.price}
            </Typography>
            <Rating
              name="star-rating"
              value={review}
              precision={0.1}
              size="large"
              sx={{ mr: '5px'}}
            />
            <Typography variant="h6" textAlign="center">
                ({props.review_total})
            </Typography>    
          </CardActions>
        </Card>
        <br></br>
      </div>    
    )
}

export default Company

