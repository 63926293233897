import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TasksTool from '../admin/Tasks'
import InfluencerItem from './InfluencerItem'
// import { Link } from 'react-router-dom'
import { useAuth, authFetch } from '../auth'
import useRoles from '../auth/useRoles'
import { Container, Grid } from '@mui/material';


const LoggedOutInfluencers=()=>{
    let storedValue = localStorage.getItem('Influencer');
    const [influencer,setInfluencer]=useState([]);

    useEffect(()=> {

        const requestOptions = {
            method: "GET",
            headers: {
                'content-type': "application/json"
            }
        }

        if (!storedValue) {
            localStorage.setItem('Influencer', '1');
            storedValue='1'
        } 
        fetch(`/api/influencer/${storedValue}`, requestOptions)
        .then(function(res) {
            if (res.status === 200) {
                return res.json();
            } else {
                throw new Error()
            }
        })
        .then(data => {
            setInfluencer(data)
            localStorage.setItem('Influencer', data.id.toString());
        })
        .catch(err=>console.log(err))
    },[]);


    return (
        <Container>
            <Link
                style={{ textDecoration: 'none' }}
                to={`/influencer/${influencer.id}`}
            >
                <InfluencerItem
                    id={influencer.id}
                    picture={influencer.picture_location}
                    username={influencer.username}
                    views={influencer.views}
                />
            </Link>
        </Container>
    )
}

const LoggedInInfluencers=()=>{
    const [influencers,setInfluencers]=useState([]);

    useEffect(()=> {

        const requestOptions = {
            method: "GET",
            headers: {
                'content-type': "application/json"
            }
        }
        authFetch('/api/influencer/list',requestOptions)
        .then(res=> res.json())
        .then(data => {
            console.log(data)
            if (data.length > 0) {
                setInfluencers(data)
            }
        })
        .catch(err=>console.log(err))
    },[setInfluencers]);

    const SelectInfluencer = (id) => {
        localStorage.setItem('Influencer', id.toString());
    }

    return (
        <div className="home container">
            <Grid 
                container
                // direction="row-reverse"
                justifycontent="center"
                alignitems="center"
            >
                
                {
                    influencers.map(
                        (influencer,index)=>(
                            <Link
                                onClick={()=>{SelectInfluencer(influencer.id)}}
                                style={{ textDecoration: 'none' }}
                                to={`/influencer/${influencer.id}`}
                            >
                                <Grid item xs={12}>
                                
                                    <InfluencerItem key={index}
                                        id={influencer.id}
                                        picture={influencer.picture_location}
                                        username={influencer.username}
                                        views={influencer.views}
                                    />
                                
                                </Grid>
                            </Link>
                        )
                    )
                }
                    
                
                <br></br>
            </Grid>
        </div>
    )

}
const InfluencerPage = ({SelectCompany}) => {
    const roles = useRoles()
    const [logged]=useAuth()

    useEffect(()=> {
        SelectCompany('brackettinnovations')
    },[]);



    return (
        <div className="home container">
            {logged ?
                    <LoggedInInfluencers/>
                :
                    <LoggedOutInfluencers/>
                }
            <br></br>
            {/* {(logged && (roles.isAdmin))?
            <div>
                <TasksTool catagory="/influencer/"/>
            </div>
            :
                ""
                } */}
        </div>
    )
}


export default InfluencerPage