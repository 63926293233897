import React,{useState} from 'react'
// import { Link } from 'react-router-dom'
// import { Card,Button } from 'react-bootstrap'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';


const Task = ({priority,task,onUp,onDown,onDelete,onRight}) => {

    return (
        <ListItem>
            <Grid container spacing={2}>
                <Grid item xs={1} md={1}>
                    {priority}
                </Grid>
                <Grid item xs={2} md={2}>
                    <ArrowCircleUpRoundedIcon onClick={onUp}/>
                    <ArrowCircleDownRoundedIcon onClick={onDown}/>
                </Grid>
                <Grid item xs={7} md={7}>
                    <Typography align="left" noWrap>
                        {task}
                    </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                <ArrowForwardRoundedIcon variant='primary' onClick={onRight}>Update</ArrowForwardRoundedIcon>
                {' '}
                <DeleteIcon variant='danger' onClick={onDelete}>Delete</DeleteIcon>
                </Grid>
            </Grid>
        </ListItem>
    )
}

export default Task;