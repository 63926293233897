import * as React from 'react';
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { logout, useAuth, authFetch } from '../auth'

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  navlogolg: {
      color: '#9BCDB0',
      width: '250px',
      marginRight: '.5em',
      marginTop: '4%',
      maxHeight: '250px',
  },
  navlogomd: {
    color: '#9BCDB0',
    width: '70px',
    marginRight: '.5em',
},
})


const settings = [{name: 'Log In', code: '/auth/login'},{name: 'Register', code: '/auth/signup'}]
const accountSettings = [{name: 'Profile', code: '/auth/profile'},{name: 'Logout', code: '/'}]


const ResponsiveAppBar = ({company,badgeSwitch,update}) => {
  const pages = [{name: 'Desctiption', code: `/company/${company}`},{name: 'Buy', code: `/company/${company}/buy`},{name: 'Reviews', code: `/company/${company}/review`}];
  
  const [logged]=useAuth()
  const [showCompany, setShowCompany] = React.useState({
    "id": 3,
    "logo": "/brackettinnovations/logo_dark.png",
    "name_view": "Brackett Innovations",
    "title": "Buisness Development Application",
    "primary_color": '#008000',
    "secondary_color": '#006400'
  });
  const [influencerPhoto, setInfluencerPhoto] = React.useState('/default.jpg')
  const [personalize, setPersonalize] = React.useState({
    "picture_location": "/brackettinnovations/logo_light.png",
    "cart_number": 0,
    "influencer_number": 1
  });
  const [anchorElUserSm, setAnchorElUserSm] = React.useState(null);
  const [anchorElUserLg, setAnchorElUserLg] = React.useState(null);
  const [anchorElNonUserSm, setAnchorElNonUserSm] = React.useState(null);
  const [anchorElNonUserLg, setAnchorElNonUserLg] = React.useState(null);
  const [badgeImage, setBadgeImage] = React.useState('/default.jpg');
  const [showBadges, setShowBadges] = React.useState(false);
  const classes = useStyles()
  
  React.useEffect(()=> {
     const requestOptions = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        }
    }

    fetch(`/api/company/bar/${company}`, requestOptions)
    .then(res=> res.json())
    .then(data => {
        localStorage.setItem('Company', data.id.toString());
        setShowCompany(data)
    })
    .catch(err=>console.log(err))  

  },[company]);

  React.useEffect(()=> {

    if (logged) {

      loggedInBadge(localStorage.getItem('Company'))
      loggedInStats()
      
    } else {
      setPersonalize({
        "picture_location": "/brackettinnovations/logo_light.png",
        "cart_number": 0,
        "influencer_number": 1
      })
      setShowBadges(false)
    }
    
    const storedValue = localStorage.getItem('Influencer');

    if (!storedValue) {
      getInfluencerImage(1)
      localStorage.setItem('Influencer', '1');
    } else {
      getInfluencerImage(storedValue)
    }     
    
  },[logged,update]);

  const loggedInBadge = (id) => {
    const requestOptions = {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
      }
    }

    authFetch(`/api/badge/company/${id}`, requestOptions)
      .then(function(res) {
        if (res.status === 200) {
            return res.json();
        } else {
            throw new Error()
        }
      })
      .then(data => {
        setShowBadges(data.showBadge)
        setBadgeImage(data.badge_image)
      })
      .catch(err=>console.log(err))    
  }

  const getInfluencerImage = (id) => {
    const requestOptions = {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
      }
    }

    fetch(`/api/influencer/${id}`, requestOptions)
      .then(function(res) {
        if (res.status === 200) {
            return res.json();
        } else {
            throw new Error()
        }
      })
      .then(data => {
        setInfluencerPhoto(data.picture_location)
      })
      .catch(err=>console.log(err))
  }

  const loggedInStats = () => {
    const requestOptions = {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
      }
    }

    authFetch('/api/auth/stats', requestOptions)
      .then(function(res) {
        if (res.status === 200) {
            return res.json();
        } else {
            throw new Error()
        }
      })
      .then(data => {
        setPersonalize(data)
      })
      .catch(err=>console.log(err))
    
  }

  const isLogout = (action) => {
    if (action==="Logout") {
      logout()
    }


  };

  const handleOpenUserMenuSm = (event) => {
    setAnchorElUserSm(event.currentTarget);
  };

  const handleCloseUserMenuSm = () => {
    setAnchorElUserSm(null);
  };

  const handleOpenUserMenuLg = (event) => {
    setAnchorElUserLg(event.currentTarget);
  };

  const handleCloseUserMenuLg = () => {
    setAnchorElUserLg(null);
  };

  const handleOpenNonUserMenuSm = (event) => {
    setAnchorElNonUserSm(event.currentTarget);
  };

  const handleCloseNonUserMenuSm = () => {
    setAnchorElNonUserSm(null);
  };

  const handleOpenNonUserMenuLg = (event) => {
    setAnchorElNonUserLg(event.currentTarget);
  };

  const handleCloseNonUserMenuLg = () => {
    setAnchorElNonUserLg(null);
  };


  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {showCompany?
            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
              <img src={process.env.PUBLIC_URL + '/img' + showCompany.logo} className={classes.navlogomd} alt='Image'/> 
            </Box>
          :
            <div>
              
            </div>
          }
          
          
          <Box sx={{
                display: { xs: 'none', md: 'inline-block' },
              }}>
            <Typography
              variant="h6"
              style={{ wordWrap: "break-word" }}
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.1.5rem',
                color: 'inherit',
                textDecoration: 'none',
                lineHeight: '1.2',
              }}
            >
              {company}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {pages.map(({name, code}) => (
            <Link key={name} to={code} style={{ textDecoration: 'none' }}>
              <Button
                key={name}
                variant="contained"
                sx={{ 
                  my: .5, 
                  color: '#E3F1E9', 
                  display: 'block',
                  marginRight: '15px',
                  marginLeft: 'auto', 
                  boxShadow: `-30px 0px 16px -10px ${showCompany.primary_color},30px 0px 16px -10px ${showCompany.primary_color}`,
                  '&:hover': {
                    boxShadow: `-30px 0px 16px -10px ${showCompany.secondary_color},30px 0px 16px -10px ${showCompany.secondary_color}`,
                    backgroundColor: 'black',
                  },
                }}
              >
                {name}
              </Button>
            </Link>
          ))}
          </Box>
          <Box sx={{display: { xs: 'none', md: 'flex' }}}>
            {showBadges?
                <Box sx={{ marginRight: '5px', marginTop: '7px'}}>
                  <Tooltip title="Badges">
                    <IconButton color="inherit">
                      <Avatar 
                          src={process.env.PUBLIC_URL + '/img' + badgeImage}
                          size="large"
                          onClick={badgeSwitch}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              :
              <div>
              </div>
            }
            <Tooltip title="Influencers">
              <Link to='/influencer' style={{ textDecoration: 'none' }}>
                <IconButton color="inherit">
                  <Badge badgeContent={personalize.influencer_number} color="secondary">
                    <Avatar 
                      alt="Influencer"
                      src={process.env.PUBLIC_URL + '/img' + influencerPhoto}
                      size="large"
                    >
                    </Avatar>
                  </Badge>
                </IconButton>
              </Link>
            </Tooltip>
            {logged?
                <Box sx={{ flexGrow: 0, marginRight: '5px', display: { xs: 'none', md: 'flex' } }}>
                    <Tooltip title="Cart">
                      <Link to='/cart' style={{ textDecoration: 'none' }}>
                        <IconButton color="inherit">
                          <Badge badgeContent={personalize.cart_number} color="secondary">
                            <Avatar alt="Cart">
                              <ShoppingCartIcon aria-label="Cart" color="secondary"/>
                            </Avatar>
                          </Badge>
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </Box>
                
              :
                <div>
                </div>
            }
          </Box>  
          <Box sx={{ flexGrow: 0, display: { xs: 'none',md: 'inline-block' } }}>
            <Tooltip title="Account">
              {logged?
                  <IconButton id="menu-appbarmd" onClick={handleOpenUserMenuSm} sx={{ p: 0 }}>
                    <Avatar 
                      src={process.env.PUBLIC_URL + '/img' + personalize.picture_location}
                      size="large"
                    />
                  </IconButton>
                :
                  <IconButton id="menu-appbar-md" onClick={handleOpenNonUserMenuSm} sx={{ p: 0 }}>
                    <Avatar alt="?">
                      <LockRoundedIcon />
                    </Avatar>
                  </IconButton>
              }
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar-md"
              anchorEl={anchorElNonUserSm}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right' ,
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right' ,
              }}
              open={Boolean(anchorElNonUserSm)}
              onClose={handleCloseNonUserMenuSm}
            >
              {settings.map(({name, code}) => (
                  <Link key={name} to={code} style={{ textDecoration: 'inherit' }}>
                    <MenuItem 
                      key={name}
                      value={code}
                      onClick={(event)=>{handleCloseNonUserMenuSm(event)}}
                    >
                      <Typography textAlign="center">{name}</Typography>
                    </MenuItem>
                  </Link>
                ))}
            </Menu>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar-md"
              anchorEl={anchorElUserSm}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right' ,
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right' ,
              }}
              open={Boolean(anchorElUserSm)}
              onClose={handleCloseUserMenuSm}
            >
              {accountSettings.map(({name, code}) => (
                  <Link key={name} to={code} style={{ textDecoration: 'none' }}>
                    <MenuItem 
                      key={name}
                      value={code}
                      onClick={(event)=>{handleCloseUserMenuSm(event); isLogout(name)}}
                    >
                      <Typography textAlign="center">{name}</Typography>
                    </MenuItem>
                  </Link>
                ))}
            </Menu>
          </Box>


          <Grid 
              container
              sx={{
              display: { xs: 'flex', md: 'none' }, 
              mr: 1,
              flexGrow: 1,
              position: 'relative',
              textAlign: 'center',
              }}
              // direction="row-reverse"
              justifycontent="center"
              alignItems="flex-start"
          >
            <Grid item xs={4}>
              <Grid 
                container
                sx={{
                display: { xs: 'flex', md: 'none' }, 
                mr: 1,
                flexGrow: 1,
                position: 'relative',
                textAlign: 'center',
                }}
                // direction="row-reverse"
                justifycontent="center"
                alignitems="center"
              >
                <Grid item xs={logged? 6: 12} marginTop='5%'>
                  <Tooltip title="Account">
                    {logged?
                      <IconButton onClick={handleOpenUserMenuLg} sx={{ p: 0 }}>
                        <Avatar 
                            src={process.env.PUBLIC_URL + '/img' + personalize.picture_location}
                            sx={{ width: 60, height: 60 }}
                          />
                      </IconButton>
                      :
                      <IconButton onClick={handleOpenNonUserMenuLg} sx={{ p: 0 }}>
                        <Avatar variant='circular'>
                          <LockRoundedIcon 
                            size="large"
                            aria-label="Account"
                            color="inherit"
                          />
                        </Avatar>
                      </IconButton>
                    }
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElNonUserLg}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={Boolean(anchorElNonUserLg)}
                    onClose={handleCloseNonUserMenuLg}
                  >
                    {settings.map(({name, code}) => (
                        <Link key={name} to={code} style={{ textDecoration: 'none' }}>
                          <MenuItem 
                            key={name}
                            value={code}
                            onClick={(event)=>{handleCloseNonUserMenuLg(event)}}
                          >
                            <Typography textAlign="center">{name}</Typography>
                          </MenuItem>
                        </Link>
                      ))}
                  </Menu>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUserLg}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={Boolean(anchorElUserLg)}
                    onClose={handleCloseUserMenuLg}
                  >
                    {accountSettings.map(({name, code}) => (
                        <Link key={name} to={code} style={{ textDecoration: 'none' }}>
                          <MenuItem 
                            key={name}
                            value={code}
                            onClick={(event)=>{handleCloseUserMenuLg(event); isLogout(name)}}
                          >
                            <Typography textAlign="center">{name}</Typography>
                          </MenuItem>
                        </Link>
                      ))}
                  </Menu>
                </Grid>
                <Grid item xs={logged? 6: 0} marginTop={logged? '6%': '0%'}>
                    {logged?
                      <Tooltip title="Cart">
                        <Link to='/cart' style={{ textDecoration: 'none' }}>
                          <IconButton color="inherit">
                            <Badge badgeContent={personalize.cart_number} color="secondary">
                              <Avatar alt="Cart">
                                <ShoppingCartIcon aria-label="Cart" color="secondary"/>
                              </Avatar>
                            </Badge>
                          </IconButton>
                        </Link>
                      </Tooltip>
                    :
                      <div>                    
                      </div>
                    }

                </Grid>
                {pages.map(({name, code}) => (
                  <Grid item xs={12} marginTop='5%'>
                    <Link key={name} to={code} style={{ textDecoration: 'none' }}>
                      <Button
                        key={name}
                        variant="contained"
                        sx={{ 
                          my: .5, 
                          color: '#E3F1E9', 
                          display: 'block',
                          marginRight: 'auto',
                          marginLeft: 'auto', 
                          boxShadow: `-30px 0px 16px -10px ${showCompany.primary_color},30px 0px 16px -10px ${showCompany.primary_color}`,
                          '&:hover': {
                            boxShadow: `-30px 0px 16px -10px ${showCompany.secondary_color},30px 0px 16px -10px ${showCompany.secondary_color}`,
                            backgroundColor: 'black',
                          },
                        }}
                      >
                        {name}
                      </Button>
                    </Link>
                  </Grid> 
                ))}
                <Grid item xs={showBadges ? 6: 0} marginTop='5%'>
                  {showBadges ?
                      <Tooltip title="Badges">
                          <IconButton color="inherit">
                              <Avatar 
                                alt="*"
                                src={process.env.PUBLIC_URL + '/img' + badgeImage}
                                onClick={badgeSwitch}
                                size="large"
                              >
                              </Avatar>
                          </IconButton>
                      </Tooltip>
                    :
                      <div></div>
                  }
                </Grid>
                <Grid item xs={showBadges ? 6: 12} marginTop='5%'>
                  <Tooltip title="Influencers">
                    <Link to='/influencer' style={{ textDecoration: 'none' }}>
                      <IconButton color="inherit">
                        <Badge badgeContent={personalize.influencer_number} color="secondary">
                          <Avatar 
                            alt="Influencer"
                            src={process.env.PUBLIC_URL + '/img' + influencerPhoto}
                            size="large"
                          />
                        </Badge>
                      </IconButton>
                    </Link>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid 
                container
                alignItems="center"
                justifyContent="center"
                sx={{
                display: { xs: 'block', md: 'none' }, 
                mr: 1,
                flexGrow: 1,
                position: 'relative',
                textAlign: 'center',
                }}
                // direction="row-reverse"
                justifycontent="center"
                alignitems="center"
              >
                <Grid item xs={12}>
                  <img src={process.env.PUBLIC_URL + '/img' + showCompany.logo} className={classes.navlogolg} alt="Logo" />
                </Grid>
                <Grid item xs={12} sx={{
                  display: { xs: 'block', md: 'none' },
                }}>
                  <Typography
                    variant="h4"
                    style={{ wordWrap: "break-word" }}
                    align="center"
                    href="/"
                    sx={{
                      fontFamily: 'monospace',
                      fontWeight: 700,
                      letterSpacing: '.1.5rem',
                      color: 'inherit',
                      textDecoration: 'none',
                      lineHeight: '1.2',
                      textAlign: 'center',
                    }}
                  >
                    {showCompany.name_view}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;