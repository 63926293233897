import { useEffect, useState } from 'react'
import TasksTool from '../admin/Tasks'
import Company from '../company/Company'
import Avatar from '@mui/material/Avatar';
import { useAuth, authFetch } from '../auth'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import useRoles from '../auth/useRoles'
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    qrimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        borderRadius: "4px 4px 4px 4px",
    },
    qrborder: {
        backgroundColor: '#13271C',
        width: '240px',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderRadius: "4px 4px 4px 4px",
    },
    compborder: {
        backgroundColor: '#13271C',
        paddingTop: '10px',
        marginBottom: '10px',
        marginTop: '20px',
        borderRadius: "4px 4px 4px 4px",
    },
    qrname: {
        color: '#e0e9e4',
    },
    qrder: {

        border: "solid",
    },
})

const QR = (props) => {
    const {username,location,company_name,company_logo,color} = props
    const classes = useStyles(props)
    const roles = useRoles()

    const [logged]=useAuth()

    return (
        <Box sx={{ justifyContent: 'center' }}>
            <Box className={classes.compborder} sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                <Avatar 
                    src={process.env.PUBLIC_URL + '/img'  + company_logo}
                    size="small"
                    sx={{ pr: '5px'}}
                />
                <Typography sx={{fontWeight: 'bold',pt: '5px', color: {color}}} variant="h5" textAlign="center" className={classes.qrname}>{company_name}</Typography>
            </Grid>
            <Box className={classes.qrborder}>
            <Grid item xs={12}>
                <Box>
                    <img 
                        src={process.env.PUBLIC_URL + '/img' + location}
                        className={classes.qrimage}
                    />
                </Box>
            </Grid>                    
            <Grid item xs={12}>
            <Typography sx={{fontWeight: 'bold'}} variant="h5" textAlign="center" className={classes.qrname}>{username.toUpperCase()}</Typography>
            </Grid></Box></Box>
        </Box>
    )
}


export default QR