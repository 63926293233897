import jwtDecode from 'jwt-decode'

const useRoles = () => {
    // const roles = ['bin-developer']
    const token=localStorage.getItem('REACT_TOKEN_AUTH_KEY')
    let isAdmin = false
    let isDeveloper = false
    let isManager = false
    if (token) {
        const decoded =jwtDecode(token)
        const roles=decoded['roles']
        if (roles)
            roles.split(',').map(element => element.trim())
            isAdmin = roles.includes('brackettinnovations-admin')

        return {isAdmin: isAdmin}
    }
    return {isAdmin}
}
export default useRoles