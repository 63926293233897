import { useEffect, useState } from 'react'
import { useAuth, authFetch } from '../auth'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { Container, Grid , Paper} from '@mui/material';
import { Rating } from '@mui/material';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block"
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
})

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const InfluencerItem = ({key,title,ranking,comment,created_at,picture_location,username}) => {
    const classes = useStyles()

    const [logged]=useAuth()

    return (
        <Container>
            <Paper elevation={3} sx={{ p: 2, display: 'flex' }}>
                <Grid container alignItems="center">
                    <Grid item xs={6} sx={{ display: 'flex'}}>
                        <Avatar src={process.env.PUBLIC_URL + '/img' + picture_location} sx={{ width: 48, height: 48 }} />
                        <div style={{ flex: 1 }}>
                            <Typography variant="subtitle1">{username}</Typography>
                            <Typography variant="caption">{created_at}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex'}}>
                        <Rating value={ranking} precision={0.1} size="small" readOnly />
                        </Grid>
                    <Grid item xs={12} sx={{ display: 'flex'}}>
                        <Typography variant="h6">{title}</Typography>
                        </Grid>
                    <Grid item xs={12} sx={{ display: 'flex'}}>
                        <Typography variant="body1">{comment}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}


export default InfluencerItem