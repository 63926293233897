import React, { useState } from "react";
import Button from '@mui/material/Button';
import { Box, Grid } from '@mui/material';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { authFetch } from "../auth"

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        width: '100%',
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }

})
export default function Card({close, address_id}) {
    const classes = useStyles()
    const [error, setError] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const [disabled, setDisabled] = useState(true)
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = () => {
        if (!stripe || !elements) {
            return;
        }
        const body = {items: [{ id: "xl-tshirt" }],address_id:{address_id}}
    
        const requestOptions = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(body)
          }
    
        authFetch('/api/order/', requestOptions)
          .then(function(res) {
              if (res.status === 201) {
                  return res.json();
              } else {
                console.log('error')
                  throw new Error('Having problems');
              }
          })
          .then(data => {
            setClientSecret(data.client_secret);
            const paymentIntent = stripe.confirmCardPayment(
                data.client_secret, {
                    payment_method: {
                        card: elements.getElement(CardElement)
                    }
                }
            )
            if (paymentIntent) {
                console.log(paymentIntent)
                close(data.id)
            } else {
                console.log('Payment Failed')
            }
          })
          .catch(err=>{
            console.log(err)
            setError(err)
          });
    }

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
      };

      const cardStyle = {
        style: {
          base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "20px",
            "::placeholder": {
              color: "#32325d"
            }
          },
          invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        }
      };

  return (
    // <form id="payment-form" onSubmit={handleSubmit}>
    <div>
      
        <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
        >
            <Grid item xs={12} sx={{ height: '2em'}}>
                <Box sx={{minWidth: '320px'}}>
                    <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                </Box>
                
            </Grid>
            <br></br>
            <Grid item xs={12}>
                <Button 
                    className={classes.btn}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Pay Now
                </Button>
            </Grid>

        </Grid>
    </div>
  );
}

