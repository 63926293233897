
import React,{useState} from 'react'
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import { Paper, Grid, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import {useForm} from 'react-hook-form'
import { login } from '../auth'
import { authFetch } from '../auth'
// import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
          },
        display: "block"
        },
    iconcol: {
        backgroundColor: "green",
    },
    paper: {
        backgroundCoor: 'red',
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }
})

const LoginPage = () => {
    const classes = useStyles()
    const { register, handleSubmit, reset } = useForm();
    const [showDanger,setShowDanger]=useState(false)
    const [show,setShow]=useState(false)
    const [serverResponse,setServerResponse]=useState('')


    const loginUser = (data) => {
        // const navigate = useNavigate()
        const requestOptions={
            method:"POST",
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify(data)
        }
        // fetch('/auth/login',requestOptions)
        fetch('/api/auth/login',requestOptions)
            .then(function(res) {
                if (res.status === 200) {
                    setShow(true)
                    setShowDanger(false)
                    return res.json();
                } else {
                    console.log(res)
                    setShowDanger(true)
                    setShow(false)
                    throw new Error('Invalid username or email and password');
                }
            })
            .then(data =>{
                login(data)
                setServerResponse('Welcome Back, you are logged on')
                const storedValue = localStorage.getItem('Influencer');
                console.log(storedValue)
    
                if (storedValue) {
                    const requestOptions2 = {
                        method: "PUT",
                        headers: {
                            'content-type': "application/json"
                        }
                    }
                    authFetch(`/api/influencer/connect/${storedValue}`, requestOptions2)
                    .then(function(res) {
                        if (res.status === 200) {
                            return res.json();
                        } else {
                            throw new Error('Connection went bad');
                        }
                    })
                    .then(data => {
                        console.log(data)
                    })
                    .catch(err=>console.log(err))

                }
                // navigate('/')
            })
            .catch(err => {
                console.log('why')
                setShowDanger(true)
                setShow(false)
                setServerResponse(err + ', please try again later.')
            })

            reset()
            }

    return (
        <Grid
      >
        <Paper elevation={5} className="App-paper">
            
            <Grid align="center">
                <Avatar
                >
                    <VpnKeyRoundedIcon color="secondary"/>
                </Avatar>
                <h2>Log In</h2>
            </Grid>
            <Container>
                {show?
                    <Box>
                       <Alert variant="outlined" severity='success' onClose={() => setShow(false)}>
                            {serverResponse}
                        </Alert>
                        {/* <small><Link to='/'>Navigate to Products</Link></small> */}
                    </Box>

                    
                    :
                    ""
                }
                {showDanger?
                    <Box>
                        <Alert variant="outlined" severity='warning' onClose={() => setShowDanger(false)} dismissible>
                            {serverResponse}
                        </Alert>
                        <small><Link to='/auth/login'>Log In to My Account</Link></small>
                        <br></br>
                        <small><Link to='/auth/login'>Recover My Account Password</Link></small>
                    </Box>
                    :
                    ''
                }
                <form>
                    <FormGroup>
                        <TextField
                            type="email"
                            className={classes.field}
                            {...register("email", { required: true })}
                            label="Username or Email"
                            variant="outlined"
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextField
                            type="password"
                            className={classes.field}
                            {...register("password", { required: true })}
                            label="Password"
                            variant="outlined"
                            name="password"
                            required
                        />
                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <Button 
                            className={classes.btn}
                            variant="contained"
                            onClick={handleSubmit(loginUser)}
                        >
                            Log In
                        </Button>
                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <small><Link to='/auth/forgot'>Forgot Password?</Link></small>
                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <small><Link to='/auth/signup'>Need an Account?</Link></small>
                    </FormGroup>
                    <br></br>
                </form> 
            </Container>
        </Paper>
    </Grid>
    )
}

export default LoginPage
