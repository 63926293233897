import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TasksTool from './admin/Tasks'
import Company from './company/Company'
import UserList from './admin/UserList'
// import { Link } from 'react-router-dom'
import { useAuth, authFetch } from './auth'
import useRoles from './auth/useRoles'
import { Container, Grid } from '@mui/material';

const HomePage = ({SelectCompany}) => {
    const [companies,setCompanies]=useState([]);
    const roles = useRoles()

    useEffect(()=> {
        SelectCompany('brackettinnovations')

        const requestOptions = {
            method: "GET",
            headers: {
                'content-type': "application/json"
            }
        }
        fetch('/api/company/list',requestOptions)
        .then(res=> res.json())
        .then(data => {
            if (data.length > 0) {
                setCompanies(data)
            }
        })
        .catch(err=>console.log(err))
    },[setCompanies]);


    const [logged]=useAuth()

    return (
        <div className="home container">
                <Grid container justifyContent="center" alignItems="center">
                    
                    {
                        
                        companies.map(
                            (company,index)=>(
                            <Link
                                onClick={()=>{SelectCompany(company.name_code); localStorage.setItem('Company', company.id.toString())}}
                                style={{ textDecoration: 'none' }}
                                to={`/company/${company.name_code}`}
                            >
                                <Grid item xs={12}>
                                    <Company key={index}
                                        logo={company.logo}
                                        title={company.title}
                                        name_view={company.name_view}
                                        cover={company.picture}
                                        description={company.short_description}
                                        primary_color={company.primary_color}
                                        review={company.review}
                                        review_total={company.review_total}
                                        price={company.price}
                                        secondary_color={company.secondary_color}
                                    />
                                </Grid>
                            </Link>
                            )
                        )
                    }
                        
                    
                    <br></br>
                </Grid>
            
            <br></br>
            {(logged && (roles.isAdmin))?
            <div>
                <TasksTool catagory="/"/>
            </div>
            :
                ""
                }
            {(logged && (roles.isAdmin))?
            <div>
                <UserList/>
            </div>
            :
                ""
                }
        </div>
    )
}


export default HomePage