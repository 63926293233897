import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth, authFetch } from '../auth'
import { Paper, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Wish from './Wish'


const LoggedInCart=()=>{
    const [orders,setOrders]=useState([]);

    useEffect(()=> {
        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        authFetch(`/api/order/list`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            setOrders(data)
        })
        .catch(err=>console.log(err))
    },[]);

    const getOrders = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        authFetch(`/api/order/list`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            setOrders(data)
        })
        .catch(err=>console.log(err))
    }

    return (
        <Paper elevation={5} className="App-paper-full">
            <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    <Grid item xs={12}>
                    <Typography variant="h3" textAlign="center">Cart</Typography>
                    </Grid>
                    
                    {
                        
                        orders.map(
                            (order,index)=>(
                                <Grid item xs={12}>
                                    <Wish key={index}
                                        id={order.id}
                                        picture={order.picture}
                                        gift_picture={order.gift_picture}
                                        total={order.total}
                                        description={order.description}
                                        quantity={order.quantity}
                                        influencer={order.influencer}
                                        checked={order.checked}
                                        company_code={order.company_view}
                                        company={order.company_name}
                                        primary_color={order.primary_color}
                                        getWishes={() => getOrders()}
                                    />
                                </Grid>
                            )
                        )
                    }
                        
                    
                    <br></br>
                    <Grid item xs={12}>
                        <Link to='/checkout' style={{ textDecoration: 'none' }}>
                            <Button 
                                sx={{ mt: 1, mr: 1,width: '100%' }} 
                                type="submit" 
                                variant="contained" 
                            >
                                Checkout
                            </Button>
                        </Link>
                    </Grid> 
                </Grid>
        </Paper>
    )
}

const CartPage = () => {
    
    const [logged]=useAuth()

    return (
        <div>
            {logged?
                <LoggedInCart />
            :
                <Paper elevation={5} className="App-paper-full">
                    Please Login to access this page
                </Paper>
            }
        </div>
    )
}


export default CartPage