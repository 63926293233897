import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { authFetch } from "../auth"
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
          },
        width: '100%',
        display: "block",
    },
    errorMsg: {
        color: 'red',
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }

})

const NewCompanyInputA = ({ initialValues, newBadge }) => {
  const classes = useStyles()
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [showDanger,setShowDanger]=useState(false)
  const [show,setShow]=useState(false)
  const [serverResponse,setServerResponse]=useState('')

  const handleInputChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation
    const errors = {};
    if (!formValues.title) {
      errors.title = 'Title is required';
    }
    if (!formValues.name_view) {
      errors.name_view = 'Name view is required';
    }
    if (!formValues.short_description) {
      errors.short_description = 'Short description is required';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors)
      return;
    }

    const requestOptions = {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(formValues)
    }
  
      authFetch(`/api/company/`, requestOptions)
        .then(function(res) {
            if (res.status === 201) {
                setShow(true)
                setShowDanger(false)
                return res.json();
            } else {
                setShowDanger(true)
                setShow(false)
                return res.json().then(data => {
                throw new Error(data.msg);
                });
            }
        })
        .then(data => {
            setServerResponse("Your Company Has been Submitted")
            setFormValues(initialValues)
            console.log(data)
            newBadge()
        })
        .catch(err=>setServerResponse(err))
  };

  const handleReset = () => {
    setFormValues(initialValues);
    setErrors({});
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <TextField
          type="text"
          name="name_view"
          label="Name"
          value={formValues.name_view}
          variant="outlined"
          className={classes.field}
          onChange={handleInputChange}
        />
        <div
            className={classes.errorMsg}
        >
            {errors.name_view && <span>{errors.name_view}</span>}
        </div>
      </div>  
      <div>
        <TextField
          type="text"
          name="title"
          label="Title"
          value={formValues.title}
          variant="outlined"
          className={classes.field}
          onChange={handleInputChange}
        />
        <div
            className={classes.errorMsg}
        >
            {errors.title && <span>{errors.title}</span>}
        </div>
      </div>
      <div>
        <TextField
          multiline
          minRows={4}
          label="Description"
          name="short_description"
          className={classes.field}
          variant="outlined"
          value={formValues.short_description}
          onChange={handleInputChange}
        />
        <div
            className={classes.errorMsg}
        >
            {errors.short_description && <span>{errors.short_description}</span>}
        </div>
      </div>
      <Button type="submit" variant="contained" className={classes.btn} sx={{ mr: '15px'}}>
        Submit
      </Button>
      <Button type="button" variant="contained" className={classes.btn} onClick={handleReset}>
        Reset
      </Button>
    </form>
  );
};

export default NewCompanyInputA;