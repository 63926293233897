import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

const SmallView = ({id,company_view,product_view,quantity, price}) => {
    
    return (
        <Grid 
            container
            // direction="row-reverse"
            justify="center"
            alignitems="center"
        >
            <Grid item xs={3} display="flex" alignItems={"center"}>
                <Typography sx={{ width: '100%'}} variant="h6" textAlign="center">
                    {company_view}:
                </Typography>
            </Grid>                  
            <Grid item xs={4} sx={{ paddingLeft: '1em' }} display="flex" alignItems={"center"}>
                <Typography textAlign="center">{product_view}</Typography>
            </Grid>
            <Grid item xs={2} sx={{ paddingLeft: '1em' }} display="flex" alignItems={"center"}>
                <Typography textAlign="center">
                    Qty: {quantity}
                </Typography>
            </Grid>
            <Grid item xs={3} display="flex" alignItems={"center"}>
                <Typography textAlign="center" variant="h6">${price}</Typography>
            </Grid>
            
        </Grid>
    )
}


export default SmallView