import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Rating } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { authFetch } from "../auth"

const useStyles = makeStyles({
    errorMsg: {
        position: "absolute",
        bottom: "-1.3em",
    },
})
const ReviewDialog = ({fetchedTitle='', company_id,updateCompany}) => {
  const classes = useStyles()
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false);
  const [ranking, setRanking] = useState(0);
  const [initialTitle, setInitialTitle] = useState('');

  useEffect(() => {
    // Simulating an asynchronous fetch to get the initial title
    setTimeout(() => {
      setInitialTitle(fetchedTitle);
      setValue('title', fetchedTitle); // Set the initial value using setValue
    }, 1000);


  }, [setValue]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    data.ranking=ranking
    data.company_id=localStorage.getItem('Company')

    const requestOptions = {
      method: "POST",
      headers: {
          'content-type': 'application/json'
      },
      body: JSON.stringify(data)
  }

    authFetch(`/api/review/company/${company_id}`, requestOptions)
      .then(function(res) {
          if (res.status === 200) {
              return res.json();
          } else {
              throw new Error()
          }
      })
      .then(data => {
          updateCompany()
      })
      .catch(err=>console.log(err))
    setValue('title', "")
    setValue('comment', "")
    setRanking(0)
    handleClose();
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>Create Reciew</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Leave a Review</DialogTitle>
        <DialogContent>
          <Rating
            name="ranking"
            value={ranking}
            onChange={(e) => setRanking(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Title"
            {...register('title', { required: true })}
            error={errors.title ? true : false}
            helperText={errors.title ? 'Title is required' : ''}
            defaultValue={initialTitle} // Set the initial value using defaultValue
            fullWidth
          />
          <div
              className={classes.errorMsg}
          >
              {errors.title?.type === "required" && <p style={{ color: "red" }}><small>Username is required</small></p>}
          </div> 
         <TextField
            margin="dense"
            label="Comment"
            {...register('comment')}
            multiline
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReviewDialog;