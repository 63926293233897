
import React,{useState} from 'react'
import PasswordIcon from '@mui/icons-material/Password';
import { Paper, Grid, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import {useForm} from 'react-hook-form'
import { login } from '../auth'
// import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
          },
        display: "block"
        },
    iconcol: {
        backgroundColor: "green",
    },
    paper: {
        backgroundCoor: 'red',
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }

})

const ForgotPage = () => {
    const classes = useStyles()
    const { register, handleSubmit, reset } = useForm();
    const [showDanger,setShowDanger]=useState(false)
    const [show,setShow]=useState(false)
    const [serverResponse,setServerResponse]=useState('')


    const emailMe = (data) => {
        // const navigate = useNavigate()

        const requestOptions={
            method:"POST",
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify(data)
        }
        fetch('/api/auth/forgot',requestOptions)
            .then(function(res) {
                if (res.status === 200) {
                    setShow(true)
                    setShowDanger(false)
                    return res.json();
                } else {
                    setShowDanger(true)
                    setShow(false)
                    throw new Error('There is no account with that email');
                }
            })
            .then(data =>{
                setServerResponse('An email was sent to you for further instruction on how to reset your password')
                // navigate('/')
                })
                .catch(err => {
                    setShowDanger(true)
                    setShow(false)
                    setServerResponse(err + ', please try again later.')
                })

                reset()
            }

    return (
        <Grid
      >
        <Paper elevation={5} className="App-paper">
            
            <Grid align="center">
                <Avatar
                >
                    <PasswordIcon color="secondary"/>
                </Avatar>
                <h2>Forgot Password</h2>
            </Grid>
            <Container>
                {show?
                    <Box>
                       <Alert variant="outlined" severity='success' onClose={() => setShow(false)}>
                            {serverResponse}
                        </Alert>
                        <small><Link to='/auth/login'>Navigate to Login</Link></small>
                    </Box>

                    
                    :
                    ""
                }
                {showDanger?
                    <Box>
                        <Alert variant="outlined" severity='warning' onClose={() => setShowDanger(false)} dismissible>
                            {serverResponse}
                        </Alert>
                        <small><Link to='/auth/login'>Navigate to Login</Link></small>
                    </Box>
                    :
                    ''
                }
                <form>
                    <FormGroup>
                        <TextField
                            type="email"
                            className={classes.field}
                            {...register("email", { required: true })}
                            label="Email"
                            variant="outlined"
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button 
                            className={classes.btn}
                            variant="contained"
                            onClick={handleSubmit(emailMe)}
                        >
                            Email Me
                        </Button>
                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <small><Link to='/auth/login'>Log in to My Account?</Link></small>
                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <small><Link to='/auth/signup'>Need an Account?</Link></small>
                    </FormGroup>
                    <br></br>
                </form> 
            </Container>
        </Paper>
    </Grid>
    )
}

export default ForgotPage
