import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import {useForm} from 'react-hook-form'
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { Paper, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import SensorOccupiedRoundedIcon from '@mui/icons-material/SensorOccupiedRounded';
import Container from '@mui/material/Container';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
          },
        width: '100%',
        display: "block",
    },
    errorMsg: {
        position: "absolute",
        bottom: "-1.3em",
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }

})
const SignUpPage = () => {
    const classes = useStyles()
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
    const [password,setPassword]=useState('')
    const [passwordDuo,setPasswordDuo]=useState('')
    const [show,setShow]=useState(false)
    const [showDanger,setShowDanger]=useState(false)
    const [serverResponse,setServerResponse]=useState('')

    const submitForm = (data) => {
        console.log(data)
        console.log(data.password === data.passwordDuo)
        if (data.password === data.passwordDuo) {

            const requestOptions = {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }

            fetch('/api/auth/signup', requestOptions)
                .then(function(res) {
                    if (res.status !== 201) {
                        setShow(false)
                        setShowDanger(true)
                        return res.json();
                    } else {
                        setShowDanger(false)
                        setShow(true)
                        return res.json();
                    }
                })
                .then(data =>{
                    setServerResponse(data.msg)
                    })
                .catch(err => {
                    console.log(err)
                    setShow(false)
                    setShowDanger(true)
                    setServerResponse(err + ', please try again later.')
                })

            reset()
        }


        else {
            alert("Passwords do not match")
        }


    }

    return (
        <Grid
      >
        <Paper elevation={5} className="App-paper">
            
            <Grid align="center">
                <Avatar
                >
                    <SensorOccupiedRoundedIcon color="secondary"/>
                </Avatar>
                <h2>Register</h2>
            </Grid>
            <Container>
                {show?
                    <Box>
                       <Alert variant="outlined" severity='success' onClose={() => setShow(false)}>
                            {serverResponse}
                        </Alert>
                        <small><Link to='/auth/login'>Log In to My Account</Link></small>
                    </Box>

                    
                    :
                    ""
                }
                {showDanger?
                    <Box>
                        <Alert variant="outlined" severity='warning' onClose={() => setShowDanger(false)} dismissible>
                            {serverResponse}
                        </Alert>
                        <small><Link to='/auth/login'>Log In to My Account</Link></small>
                        <br></br>
                        <small><Link to='/auth/login'>Recover My Account Password</Link></small>
                    </Box>
                    :
                    ''
                }
                <form 
                    noValidate 
                    autoComplete="off"
                >
                    <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="text"
                            {...register("username", { required: true, maxLength: 25 })}
                            className={classes.field}
                            label="Username"
                            required
                            error={errors.username}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {errors.username?.type === "required" && <p style={{ color: "red" }}><small>Username is required</small></p>}
                            {errors.username?.type === "maxLength" && <p style={{ color: "red" }}><small>Username must be less than 25 characters</small></p>}
                        </div> 
                        
                    </FormGroup>
                    <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="email"
                            className={classes.field}
                            {...register("email", { required: true, maxLength: 25 })}
                            label="Email"
                            variant="outlined"
                            required
                            error={errors.email}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {errors.email?.type === "required" && <p style={{ color: "red" }}><small>Email is required</small></p>}
                            {errors.email?.type === "maxLength" && <p style={{ color: "red" }}><small>Email must be less than 25 characters</small></p>}
                        </div> 
                    </FormGroup>
                    <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="password"
                            className={classes.field}
                            {...register("password", { required: true, maxLength: 25 })}
                            onChange={(e)=>setPassword(e.target.value)}
                            label="Password"
                            variant="outlined"
                            name="password"
                            required
                            error={errors.password}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {errors.password?.type === "required" && <p style={{ color: "red" }}><small>Password is required</small></p>}
                            {errors.password?.type === "maxLength" && <p style={{ color: "red" }}><small>Password must be less than 25 characters</small></p>}
                        </div> 
                    </FormGroup>
                    <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="password"
                            className={classes.field}
                            {...register("passwordDuo", { required: true, validate: (getValues("password") == (getValues("passwordDuo")))})}
                            onChange={(e)=>setPasswordDuo(e.target.value)}
                            label="Confirm Password"
                            variant="outlined"
                            required
                            error={(password!==passwordDuo)}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {(password!==passwordDuo) && <p style={{ color: "red" }}><small>Passwords must match</small></p>}
                        </div> 
                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <Button 
                            className={classes.btn}
                            variant="contained"
                            onClick={handleSubmit(submitForm)}
                        >
                            SignUp
                        </Button>
                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <small>Already have an account?  <Link to='/auth/login'>Log in here</Link></small>
                    </FormGroup>
                    <br></br>
                </form>
            </Container>
        </Paper>
    </Grid>
    )
    }

export default SignUpPage