import Avatar from '@mui/material/Avatar';
import { Container, Grid , Paper} from '@mui/material';
import Typography from '@mui/material/Typography';

const InfluencerItem = ({key,id,picture,username,views}) => {

    return (
        <Container>
            <Paper elevation={5} className="App-paper-full" style={{ color: "black" , borderWidth: 1, borderRadius: 20, borderColor: 'black'}}>
            <Grid 
                container
                // direction="row-reverse"
                justify="center"
                alignitems="center"
            >
                <Grid item xs={2} display="flex" alignItems={"center"}>
                    <Avatar 
                        alt={username} 
                        src={process.env.PUBLIC_URL + '/img' + picture}
                        sx={{ width: 60, height: 60 }}
                    />
                </Grid>
                <Grid item xs={4} display="flex" alignItems={"center"}>
                    <Typography textAlign="center" variant="h5">{username}</Typography>
                </Grid> 
                
                <Grid item xs={3} display="flex" alignItems={"center"}>
                    <Typography textAlign="center">Id: {id}</Typography>
                </Grid>
                <Grid item xs={3} display="flex" alignItems={"center"}>
                    <Typography textAlign="center">Views: {views}</Typography>
                </Grid>

            </Grid>
        </Paper>
        </Container>
    )
}


export default InfluencerItem