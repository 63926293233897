import React, { useEffect, useState } from 'react'
import PasswordIcon from '@mui/icons-material/Password';
import { Paper, Grid } from '@mui/material';
import List from '@mui/material/List';

import Task from "./Task"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { authFetch } from "../auth"

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    comptask: {
        backgroundColor: "green",
    },
    todotask: {
        backgroundColor: "pink",
    },
    num: {
        width: "2em",
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }

})

function generate(element) {
    return [0, 1, 2].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
  }

const TasksTool = ({catagory}) => {
    const classes = useStyles()
    const [taskId, setId] = React.useState(0);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [pendingTasks, setPendingTasks] = React.useState([]);
    const [inputText, setInputText] = useState('');
    const [implementedTasks, setImplementedTasks] = React.useState([]);


    const handleClickOpen = () => {
        setOpenDelete(true);
      };
    
    const handleClose = () => {
        setOpenDelete(false);
      };

    const handleAddClose = () => {
        setOpenAdd(false);
      };
    
    const handleAddOpen = () => {
        setOpenAdd(true);
      };
    
    useEffect(()=> {
        const data = {
            catagory: catagory,
            progress: 'pending'
        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data)
        }

        authFetch('/api/tasks/page', requestOptions)
        .then(res=> res.json())
        .then(data => {
            if (Array.isArray(data))
                setPendingTasks(data)
        })
        .catch(err=>console.log(err))

        const data2 = {
            catagory: catagory,
            progress: 'implemented'
        }

        const requestOptions2 = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data2)
        }

        authFetch('/api/tasks/page', requestOptions2)
        .then(res=> res.json())
        .then(data => {
            if (Array.isArray(data))
                setImplementedTasks(data)
        })
        .catch(err=>console.log(err))
    },[]);

    const getAllTasks = () => {
        const data = {
            catagory: catagory,
            progress: 'pending'
        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data)
        }

        authFetch('/api/tasks/page', requestOptions)
        .then(res=> res.json())
        .then(data => {
            if (Array.isArray(data)) 
                setPendingTasks(data)
        })
        .catch(err=>console.log(err))

        const data2 = {
            catagory: catagory,
            progress: 'implemented'
        }

        const requestOptions2 = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data2)
        }

        authFetch('/api/tasks/page', requestOptions2)
        .then(res=> res.json())
        .then(data => {
            if (Array.isArray(data)) 
                setImplementedTasks(data)
        })
        .catch(err=>console.log(err))
      };

    const updateTaskPriority=(id,direction)=>{
        const data = {
            direction: direction
        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data)
        }

        authFetch(`/api/tasks/${id}/move`,requestOptions)
        .then(res=>res.json())
        .then(data=>{
            console.log(data)
            getAllTasks()
        })
        .catch(err=>console.log(err))
    }

    const updateTaskProgress=(id)=>{
        const requestOptions = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            }
        }

        authFetch(`/api/tasks/${id}/update`,requestOptions)
        .then(res=>res.json())
        .then(data=>{
            getAllTasks()
        })
        .catch(err=>console.log(err))
    }
    
    const deleteTask=()=>{

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
            }

        }

        authFetch(`/api/tasks/${taskId}/update`,requestOptions)
        .then(res=>res.json())
        .then(data=>{
            getAllTasks()
            setOpenDelete(false)
            getAllTasks()

        })
        .catch(err=>console.log(err))
    }

    const addTask=()=>{
        const data = {
            priority:0,
            catagory: catagory,
            task:inputText,
            progress: 'pending'
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data)
        }

        authFetch('/api/tasks/add', requestOptions)
        .then(res=> res.json())
        .then(data => {
            getAllTasks()
            setOpenAdd(false)
        })
        .catch(err=>console.log(err))

      };

    

    return (
        <Paper elevation={5} className="App-paper-full">
            <Grid
                container
                direction="row"
                spacing={2}
            >
                <Grid item xs={12} sm={6} className={classes.todotask}>
                
                    <List dense={true}>
                        {
                            pendingTasks.map(
                                (task,index)=>(
                                    <Task priority={task.priority} 
                                    key={index}
                                    task={task.task}
                                    onUp={()=>{updateTaskPriority(task.id,"up")}}
                                    onDown={()=>{updateTaskPriority(task.id,"down")}}
                                    onRight={()=>{updateTaskProgress(task.id)}}
                                    onDelete={()=>{setOpenDelete(true); setId(task.id)}}
                                    />
                                )
                            )
                        }
                    </List>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.comptask}>
                    <List dense={true}>
                        {
                            implementedTasks.map(
                                (task,index)=>(
                                    <Task priority={task.priority} 
                                    key={index}
                                    task={task.task}
                                    onUp={()=>{updateTaskPriority(task.id,"up")}}
                                    onDown={()=>{updateTaskPriority(task.id,"down")}}
                                    onRight={()=>{updateTaskProgress(task.id)}}
                                    onDelete={()=>{setOpenDelete(true); setId(task.id)}}
                                    />
                                )
                            )
                        }
                    </List>
                </Grid>
            </Grid>
            <Button 
                className={classes.btn}
                variant="contained"
                onClick={handleAddOpen}
            >
                Add
            </Button>
            <Dialog
                open={openDelete}
                onClose={handleClose}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">
                {"Delete a task?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="delete-dialog-description">
                    Are you sure you want to delete this task?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={()=>deleteTask()} autoFocus>
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openAdd}
                onClose={handleAddClose}
                aria-labelledby="add-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="add-dialog-title">
                {"Add a task?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="add-dialog-description">
                <TextField
                    autoFocus
                    margin="dense"
                    id="taskInput"
                    label="Task"
                    type="text"
                    fullWidth
                    onChange={(e) => {
                        setInputText(e.target.value);
                         }                   
                   }
                    variant="standard"
                />
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleAddClose}>Cancel</Button>
                <Button onClick={()=>addTask()} autoFocus>
                    Add
                </Button>
                </DialogActions> 
            </Dialog>
        </Paper>
    )
}

export default TasksTool
