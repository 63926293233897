import { Link } from 'react-router-dom'
import { authFetch } from '../auth'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox'

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block"
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
})

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Wish = ({key,id,picture,gift_picture,total,description,quantity,influencer,checked,company,primary_color,getWishes,company_code}) => {
    const classes = useStyles()

    const changeClicked = () =>{
        console.log(id)
        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        authFetch(`/api/order/${id}/wish/update`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            console.log(data.msg)
        })
        .catch(err=>console.log(err))

    }

    const deleteWish = () =>{
        console.log(id)
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
            }
        }

        authFetch(`/api/order/${id}/wish/update`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            getWishes()
        })
        .catch(err=>console.log(err))

    }
    return (
        <Grid 
            container
            // direction="row-reverse"
            justify="center"
            alignitems="center"
        >
            <Grid item xs={1} sx={{ paddingRight: '.5em' }} display="flex" alignItems={"center"}>
                <Box>
                    <Checkbox {...label} onClick={changeClicked} defaultChecked={checked? true: false}/>
                </Box>
            </Grid>
            <Grid item xs={3} md={2} display="flex" alignItems={"center"}>
                <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    <Grid item xs={12} display="flex" alignItems={"center"}>
                        <Box>
                            <img 
                                src={process.env.PUBLIC_URL + '/img' + picture}
                                className={classes.productimage}
                            />
                        </Box>
                    </Grid>
                    {gift_picture?
                        <Grid item xs={12} display="flex" alignItems={"center"}>
                            <Box disply='flex'>
                                <img 
                                    src={process.env.PUBLIC_URL + '/img' + gift_picture} 
                                    className={classes.productimage}
                                />
                            </Box>
                        </Grid>
                    :
                        ""
                    }
                </Grid>
            </Grid>
            <Grid item xs={8} md={7} display="flex" alignItems={"center"}>
                <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="center"
                    >
                    <Grid item xs={12} display="flex" alignItems={"center"}>
                        <Typography sx={{ color: primary_color, width: '100%'}} variant="h6" textAlign="center">
                            {company}
                        </Typography>
                    </Grid>
                    {influencer?
                        <Grid item xs={12} display="flex" alignItems={"center"}>
                            <Typography textAlign="center" sx={{ width: '100%'}} variant="subtitle2">Influencer: {influencer}</Typography>
                        </Grid>
                    :
                        <div></div>
                    }
                    
                    <Grid item xs={12} sx={{ paddingLeft: '1em' }} display="flex" alignItems={"center"}>
                        <Typography textAlign="center">{description}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ paddingLeft: '1em' }} display="flex" alignItems={"center"}>
                        <Typography textAlign="center">
                            Quantity: {quantity}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" alignItems={"center"}>
                        <Typography textAlign="center" variant="h5">Total: ${total}</Typography>
                    </Grid>
                    
                    
                    {/* { xs: 'block', md: 'none' } */}
                </Grid>
            </Grid>
            <Grid item xs={12} md={2} alignItems={"center"}>
                <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="center"
                >
                    <Grid item xs={6} md={12} alignItems={"center"}>
                        <Link to={'/company/' + company_code + '/order/' + id} style={{ textDecoration: 'none' }}>
                            <Button
                                key={key}
                                variant="outlined"
                                sx={{ 
                                my: .5, 
                                display: 'block',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                width: '100%',
                                }}
                            >
                                Edit
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6} md={12} alignItems={"center"}>
                        <Button
                            key={key}
                            variant="outlined"
                            onClick={deleteWish}
                            sx={{ 
                            my: .5, 
                            display: 'block',
                            marginRight: 'auto',
                            width: '100%', 
                            marginLeft: 'auto',    
                            }}
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <br></br>
        </Grid>
    )
}


export default Wish