import * as React from 'react';
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import useRoles from '../auth/useRoles'
import Confetti from '../badge/Confetti'

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  navlogolg: {
      color: '#9BCDB0',
      width: '100%',
      marginRight: '.5em',
  },
  badgeimage: {
    color: '#9BCDB0',
    marginRight: '.5em',
},
})



const StakeholderBar = ({company, show}) => {
  const [showInfluencer, setShowInflunecer] = React.useState(null)
  const classes = useStyles()
  const roles = useRoles()

  React.useEffect(()=> {

  },[]);


  return (
    <AppBar position="static">
      {show?
        <Box className="App-influencer" sx={{ width: '100vw' }}>
          
            {roles.isAdmin || showInfluencer?
              <Grid 
                  container
                  // direction="row-reverse"
                  justifycontent="center"
                  alignitems="center"
              >
              
                {/* <img src={process.env.PUBLIC_URL + '/img' + showCompany.logo} className={classes.navlogosm} alt='Image'/>  */}
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={2}>
                  <Link to={'/company/' + company + '/qr'} style={{ textDecoration: 'none' }}>
                    <Button
                      variant="contained"
                      sx={{ 
                        color: '#3CE8EC', 
                        border: 'solid #3CE8EC',
                        height: '2em',
                        marginTop: '.4em',
                        boxShadow: '-30px 0px 16px -10px #3CE8EC,30px 0px 16px -10px #3CE8EC',
                        '&:hover': {
                          boxShadow: '-30px 0px 16px -10px #E3F1E9,30px 0px 16px -10px #E3F1E9',
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      QR
                    </Button>
                  </Link>
                  </Grid>
                  <Grid item xs={2} justifycontent="center">

                    <Box sx={{ display: 'flex',
                          width: '100%', margin: 'auto'}} alignitems="center" justifyContent="center" display="flex">
                      <Avatar 
                          src={process.env.PUBLIC_URL + '/img/badges/influencer_light.png'}
                          sx={{ width: 40, height: 40 }}
                          className={classes.badgeimage}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Link to={'/company/' + company + '/orders'} style={{ textDecoration: 'none' }}>
                      <Button
                        variant="contained"
                        sx={{ 
                          color: '#3CE8EC',
                          height: '2em',
                          marginTop: '.4em',
                          border: 'solid #3CE8EC', 
                          boxShadow: '-30px 0px 16px -10px #3CE8EC,30px 0px 16px -10px #3CE8EC',
                          '&:hover': {
                            boxShadow: '-30px 0px 16px -10px #E3F1E9,30px 0px 16px -10px #E3F1E9',
                            backgroundColor: 'black',
                          },
                        }}
                      >
                        Order
                      </Button>
                    </Link>
                </Grid>
                <Grid item xs={3}>
                  
                </Grid>
              </Grid>
              
            :
              <div></div>
            }  
            <Confetti/>
          </Box>
        :
        <div></div>
      }
    </AppBar>
  );
};
export default StakeholderBar;