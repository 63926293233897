import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block"
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
})

const Order = ({key,id,picture,description,quantity,raw_price,stat}) => {
    const classes = useStyles()

    return (
        <Grid 
            container
            // direction="row-reverse"
            justify="center"
            alignitems="center"
        >
            <Grid item xs={3} display="flex" alignItems={"center"}>
                <Typography textAlign="center" variant="h5">{stat}</Typography>
            </Grid> 
            <Grid item xs={3} sm={5} display="flex" alignItems={"center"}>
                <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    <Grid item xs={12} sm={3} display="flex" alignItems={"center"}>
                        <Box>
                            <img 
                                src={process.env.PUBLIC_URL + '/img' + picture}
                                className={classes.productimage}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} display="flex" alignItems={"center"}>
                        <Typography textAlign="center">
                            Qty: {quantity}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} display="flex" alignItems={"center"}>
                        <Typography textAlign="center">Total: ${raw_price}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} sm={4} sx={{ paddingLeft: '1em' }} display="flex" alignItems={"center"}>
                <Typography textAlign="center">{description}</Typography>
            </Grid> 

        </Grid>
    )
}


export default Order