
import React,{useState,useEffect} from 'react'
import LockResetIcon from '@mui/icons-material/LockReset';
import { Paper, Grid, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import {useForm} from 'react-hook-form'
import { login } from '../auth'
// import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
          },
        display: "block"
        },
    errorRoot: {
        position: "relative",
    },
    errorMsg: {
        position: "absolute",
        bottom: "-1.3em",
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }

})

const ResetPwdPage = () => {
    const classes = useStyles()
    const { register, handleSubmit, getValues, setValue, reset, formState: { errors } } = useForm();
    const [password,setPassword]=useState('')
    const [passwordDuo,setPasswordDuo]=useState('')
    const [showDanger,setShowDanger]=useState(false)
    const [show,setShow]=useState(false)
    const [serverResponse,setServerResponse]=useState('')
    // const [emailedToken,setEmailedToken]=useState('')

    useEffect(()=> {
        const input = window.location.search.substring(1).split("&")

        for (let i=0; i<input.length;i++) {
            setValue(input[i].split("=")[0],input[i].split("=")[1])
            console.log(input[i].split("=")[0] + ' is set to ' + input[i].split("=")[1])
        }
    },[]);


    const resetPassword = (data) => {
        // const navigate = useNavigate()

        const requestOptions={
            method:"POST",
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify(data)
        }
        fetch('/api/auth/reset',requestOptions)
            .then(function(res) {
                if (res.status === 201) {
                    return res.json();
                } else if (res.status === 400) {
                    setShowDanger(true)
                    setShow(false)
                    throw new Error('There is no account with that email');
                } else if (res.status === 401) {
                    setShowDanger(true)
                    setShow(false)
                    throw new Error('That is an invalid or expired token');
                }{
                    setShowDanger(true)
                    setShow(false)
                    throw new Error('There is no account with that email');
                }
            })
            .then(data =>{
                setServerResponse('Your password has been reset feel free to log in.')
                setShow(true)
                setShowDanger(false)
                // navigate('/')
                })
                .catch(err => {
                    setShowDanger(true)
                    setShow(false)
                    setServerResponse(err + ', please try again later.')
                })

                reset()
            }

    return (
        <Grid
      >
        <Paper elevation={5} className="App-paper">
            
            <Grid align="center">
                <Avatar
                >
                    <LockResetIcon color="secondary"/>
                </Avatar>
                <h2>Reset Password</h2>
            </Grid>
            <Container>
                {show?
                    <Box>
                       <Alert variant="outlined" severity='success' onClose={() => setShow(false)}>
                            {serverResponse}
                        </Alert>
                        <small><Link to='/auth/login'>Navigate to Login</Link></small>
                    </Box>

                    
                    :
                    ""
                }
                {showDanger?
                    <Box>
                        <Alert variant="outlined" severity='warning' onClose={() => setShowDanger(false)} dismissible>
                            {serverResponse}
                        </Alert>
                        <small><Link to='/auth/forgot'>Email a New Token</Link></small>
                    </Box>
                    :
                    ''
                }
                <form>
                    <FormGroup>
                        <TextField
                            type="email"
                            className={classes.field}
                            {...register("email", { required: true })}
                            label="Email"
                            variant="outlined"
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextField
                            type="email"
                            className={classes.field}
                            {...register("emailedtoken", { required: true })}
                            label="Token found in Email"
                            variant="outlined"
                            required
                        />
                    </FormGroup>
                    <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="password"
                            className={classes.field}
                            {...register("password", { required: true, maxLength: 25 })}
                            onChange={(e)=>setPassword(e.target.value)}
                            label="Password"
                            variant="outlined"
                            name="password"
                            required
                            error={errors.password}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {errors.password?.type === "required" && <p style={{ color: "red" }}><small>Password is required</small></p>}
                            {errors.password?.type === "maxLength" && <p style={{ color: "red" }}><small>Password must be less than 25 characters</small></p>}
                        </div> 
                    </FormGroup>
                    <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="password"
                            className={classes.field}
                            {...register("passwordDuo", { required: true, validate: (getValues("password") == (getValues("passwordDuo")))})}
                            onChange={(e)=>setPasswordDuo(e.target.value)}
                            label="Confirm Password"
                            variant="outlined"
                            required
                            error={(password!==passwordDuo)}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {(password!==passwordDuo) && <p style={{ color: "red" }}><small>Passwords must match</small></p>}
                        </div> 
                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <Button 
                            className={classes.btn}
                            variant="contained"
                            onClick={handleSubmit(resetPassword)}
                        >
                            Reset Password
                        </Button>
                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <small><Link to='/auth/forgot'>Need a new Token?</Link></small>
                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <small><Link to='/auth/login'>Log in to My Account?</Link></small>
                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <small><Link to='/auth/signup'>Need an Account?</Link></small>
                    </FormGroup>
                    <br></br>
                </form> 
            </Container>
        </Paper>
    </Grid>
    )
}

export default ResetPwdPage
