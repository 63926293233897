import { useEffect, useState } from 'react'
import TasksTool from '../admin/Tasks'
import QR from '../company/QR'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button';
import { useAuth, authFetch } from '../auth'
import useRoles from '../auth/useRoles'
import { Container, Grid } from '@mui/material';

// import CompanyThumbnail from './company/CompanyThumbnail'
// import CardGroup from 'react-bootstrap/CardGroup'

// import { Modal ,Form,Button, ModalBody} from 'react-bootstrap'

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
          },
        display: "block"
        },
    iconcol: {
        backgroundColor: "green",
    },
    paper: {
        backgroundCoor: 'red',
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }
})
const LoggedInHome=({company})=>{
    const classes = useStyles()
    const [qrs,setQrs]=useState([]);
    const roles = useRoles()

    useEffect(()=> {
        const body = {
            company: company,
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }
    
        authFetch(`/api/order/qr/${window.location.href.toString().split('/')[4]}`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            setQrs(data)
        })
        .catch(err=>console.log(err))

    },[]);

    const getQRs = () => {
        const body = {
            company: company,
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }
    
        authFetch(`/api/order/qr/${window.location.href.toString().split('/')[4]}`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            setQrs(data)
        })
        .catch(err=>console.log(err))
    }

    const handleSubmit = () => {
        let storedValue = localStorage.getItem('Company');

        if (!storedValue) {
            storedValue='1'
        } 

        const body = {
            company: storedValue,
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(body)
        }
    
        authFetch(`/api/order/qr`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            console.log(data.msg)
            getQRs()
        })
        .catch(err=>console.log(err))
        getQRs()
    }

    return (
        <Container className="home">
            <Grid container justifyContent="center" alignItems="center">
                {
                            
                    qrs.map(
                        (qr,index)=>(
                        <Link
                            style={{ textDecoration: 'none' }}
                            to={qr.url}
                        >
                            <Grid item xs={12} sx={{m: '5px'}}>
                                <QR key={index}
                                    location={qr.location}
                                    username={qr.name}
                                    color={qr.color}
                                    company_name={qr.company_name}
                                    company_logo={qr.company_logo}
                                />
                            </Grid>
                        </Link>
                        )
                    )
                }
            </Grid>
            {roles.isAdmin ?
                        <Grid item xs={12}>
                            <Button 
                                className={classes.btn}
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Create QR
                            </Button>
                        </Grid>
                    :
                    <div>
                    </div>
                }
        </Container>
    )
}

const QRPage = ({company}) => {

    const [logged]=useAuth()

    return (
        <div className="home container">
            <Grid 
                    container
                    // direction="row-reverse"
                    justifycontent="center"
                    alignitems="center"
                >
                {logged ?
                    <LoggedInHome company={company}/>
                :
                    <div>
                        You must be logged in to access a QR Code
                    </div>
                }
                <br></br>
            </Grid>
        </div>
    )
}


export default QRPage