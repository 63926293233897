import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TasksTool from '../admin/Tasks'
import ReviewDialog from './ReviewDialog';
import ReviewItem from './ReviewItem';
// import { Link } from 'react-router-dom'
import { useAuth, authFetch } from '../auth'
import useRoles from '../auth/useRoles'
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { Rating } from '@mui/material';
import { Paper, Grid } from '@mui/material';


const ReviewPage = ({company}) => {
    const [reviews,setReviews]=useState([]);
    const [companyTotalReviews,setCompanyTotalReviews]=useState({
        "id": 0,
        "review": 5,
        "review_total":0,
    });
    const roles = useRoles()

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: {
                'content-type': "application/json"
            }
        }
    
    
        fetch(`/api/review/company/total/${company}`, requestOptions)
            .then(function(res) {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error()
                }
            })
            .then(data => {
                setCompanyTotalReviews(data)
                fetch(`/api/review/company/${data.id}`, requestOptions)
                    .then(function(res) {
                        if (res.status === 200) {
                            return res.json();
                        } else {
                            throw new Error()
                        }
                    })
                    .then(data => {
                        if (data.length > 0) {
                            setReviews(data)
                        }
                    })
                    .catch(err=>console.log(err))
            })
            .catch(err=>console.log(err))
    
    }, []);

    const UpdateCompany = () => {
        const requestOptions = {
            method: "GET",
            headers: {
                'content-type': "application/json"
            }
        }
    
    
        fetch(`/api/review/company/total/${company}`, requestOptions)
            .then(function(res) {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error()
                }
            })
            .then(data => {
                setCompanyTotalReviews(data)
                fetch(`/api/review/company/${data.id}`, requestOptions)
                    .then(function(res) {
                        if (res.status === 200) {
                            return res.json();
                        } else {
                            throw new Error()
                        }
                    })
                    .then(data => {
                        if (data.length > 0) {
                            setReviews(data)
                        }
                    })
                    .catch(err=>console.log(err))
            })
            .catch(err=>console.log(err))

    }


    const [logged]=useAuth()

    return (
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Paper elevation={5} className="App-paper" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={6} sx={{ display: 'flex'}}>
                <Rating
                    name="star-rating"
                    value={companyTotalReviews.review}
                    precision={0.1}
                    size="large"
                    sx={{ mr: '5px'}}
                />
                <Typography variant="h6" textAlign="center"  sx={{ mr: '5px'}}>
                    {companyTotalReviews.review}
                </Typography>
                <Typography variant="h6" textAlign="center">
                    ({companyTotalReviews.review_total})
                </Typography>
                </Grid>
                <Grid item xs={6}>
                {logged ? (
                    <ReviewDialog company_id={companyTotalReviews.id} updateCompany={()=>{UpdateCompany()}}/>
                ) : (
                    <Link to="/auth/login" style={{ textDecoration: 'none' }}>
                    <Button variant="contained">Log In to Leave a Review</Button>
                    </Link>
                )}
                </Grid>
            </Grid>
            </Paper>
            {reviews.map((review, index) => (
                <Grid item xs={12}>
                <ReviewItem
                    key={review.id}
                    id={review.id}
                    picture_location={review.picture_location}
                    username={review.username}
                    ranking={review.ranking}
                    comment={review.comment}
                    title={review.title}
                    created_at={review.created_at}
                />
                </Grid>
            ))}
        </Grid>
        </Grid>
    )
}


export default ReviewPage