import React, { useEffect, useState } from 'react'
import { Paper, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import { useAuth } from './auth'


const AboutPage = () => {
    const [logged]=useAuth()

    return (
        <Grid 
            justifycontent="center"
            alignitems="center"
        >
            <Grid item xs={12}>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="body1">
                        Brackett Innovations is a new ecommerce platform focused on small business development and success. 
                        Our goal is to decrease initial start up costs by creating a free platform to advertize small companies and to access influencers for marketing.
                    </Typography>
                </Paper>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="h4">
                        Influencer Opportunities
                    </Typography>
                    <Typography align="left" variant="body1">
                        Brackett Innovations is a new ecommerce platform focused on small business development and success. 
                        This business model makes every purchase the potential for a small influencer business.
                        Our goal to decrease initial start up costs by creating a free platform to advertize small companies and access influencers.
                    </Typography>
                </Paper>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="h4">
                        Business Opportunity
                    </Typography>
                    <Typography align="left" variant="body1">
                        It is very easy to add a business to Brackett Innovations and completely free. Requirements to add a company to Brackett Innovations:
                    </Typography>
                    <ul>
                        <li>
                            Price of each product must be the same as the product found on the official business website.
                        </li>
                        <li>
                            The business should have a defined finders fee to pay each influencer that encourages the sale.
                        </li>
                    </ul>
                    {logged?
                        <Link to={`/new/company`} style={{ textDecoration: 'none'}}>
                            <Button
                              variant="contained"
                              sx={{ 
                                my: .5, 
                                color: '#E3F1E9', 
                                display: 'block',
                                marginRight: 'auto',
                                marginLeft: 'auto', 
                                boxShadow: `-30px 0px 16px -10px #9BCDB0,30px 0px 16px -10px #9BCDB0`,
                                '&:hover': {
                                  boxShadow: `-30px 0px 16px -10px #E3F1E9,30px 0px 16px -10px #E3F1E9`,
                                  backgroundColor: 'black',
                                },
                              }}
                            >
                              Add a business
                            </Button>
                          </Link>
                        :
                            <Typography align="left" variant="body1">
                                Log in to add a Small Business to Brackett Innovations product list
                            </Typography>
                    }
                </Paper>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="h4">
                        Contact Us
                    </Typography>
                    <Typography align="left" variant="body1">
                    Feel free to contact us with complications, feedback, or questions.  Any feedback is good feedback. We will try to get back you as soon as possible.
                    </Typography>
                    <Typography align="left" variant="body1">
                        Email: brackettinnovations.feedback@gmail.com
                    </Typography>
                </Paper>
            </Grid>

        </Grid>  
    )
}


export default AboutPage