import React, {useEffect, useState} from'react';
import ReactConfetti from 'react-confetti';

const Confetti = () => {
    const [windowDimension, setWindowDimension] = useState({width: window.innerWidth, height: window.innerHeight})
    const [Btn, setBtn] = useState(false);
    const detectSize = () =>{
        setWindowDimension({width: window.innerWidth, height: window.innerHeight});
    }

    useEffect(()=>{
        window.addEventListener('resize',detectSize);
        return()=>{
            window.removeEventListener('resize',detectSize);
        }
    },[windowDimension]);
    return (
        <div>
            <button onClick={()=>setBtn(!Btn)}>Confetti On</button>
            {Btn && <ReactConfetti>
                width={100}
                height={100}
                tweenDuration={10}
                
            </ReactConfetti>}
        </div>
    )
}

export default Confetti;