import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { authFetch } from "../auth"


export default function BasicTable() {
    const [rows, setRows] = React.useState([]);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [userId, setId] = React.useState(0);
    const [inputText, setInputText] = useState('');

    const handleClickOpen = () => {
        setOpenEdit(true);
      };
    
    const handleClose = () => {
        setOpenEdit(false);
      };

    useEffect(()=> {
        const token=localStorage.getItem('REACT_TOKEN_AUTH_KEY')
        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(token).access_token}`
            }
        }

        fetch('/api/auth/signup', requestOptions)
        .then(res=> res.json())
        .then(data => {

            setRows(data)
        })
        .catch(err=>console.log(err))
    },[]);


    const editUser=()=>{
        const token=localStorage.getItem('REACT_TOKEN_AUTH_KEY')
        setOpenEdit(false);
        const data = {
            permission: inputText
        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(token).access_token}`
            },
            body: JSON.stringify(data)
        }

        authFetch(`/api/auth/${userId}/update`,requestOptions)
        .then(res=>res.json())
        .then(data=>{
            getAllUsers()
        })
        .catch(err=>console.log(err))
    }
    
    const getAllUsers = () => {
        const token=localStorage.getItem('REACT_TOKEN_AUTH_KEY')
        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(token).access_token}`
            }
        }

        fetch('/api/auth/signup', requestOptions)
        .then(res=> res.json())
        .then(data => {

            setRows(data)
        })
        .catch(err=>console.log(err))
      };

  return (
    <Paper elevation={5} className="App-paper-full">
        <TableContainer>
        <Table sx={{ minWidth: 650, maxWidth: 800 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Permissions</TableCell>
                <TableCell>Edit</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row">
                    {row.id}
                </TableCell>
                <TableCell align="right">{row.username}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.permissions}</TableCell>
                <TableCell align="right">
                    <EditIcon variant='primary' onClick={()=>{handleClickOpen(); setId(row.id)}}>Update</EditIcon>
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        <Dialog
                open={openEdit}
                onClose={handleClose}
                aria-labelledby="edit-dialog-title"
                aria-describedby="edit-dialog-description"
            >
                <DialogTitle id="edit-dialog-title">
                {"Edit a User Permission"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="add-dialog-description">
                <TextField
                    autoFocus
                    margin="dense"
                    id="PermissionInput"
                    label="Permissions [bin-admin,psl-influencer]"
                    type="text"
                    fullWidth
                    onChange={(e) => {
                        setInputText(e.target.value);
                         }                   
                   }
                    variant="standard"
                />
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={()=>editUser()} autoFocus>
                    Edit
                </Button>
                </DialogActions> 
            </Dialog>
    </Paper>
  );
}
