import React, { useEffect, useState } from 'react'
import {useForm} from 'react-hook-form'
import Box from '@mui/material/Box';
import { Paper, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import BrushRoundedIcon from '@mui/icons-material/BrushRounded';
import { login, logout, useAuth } from '../auth'
import useRoles from './useRoles'
import TasksTool from '../admin/Tasks'
import { authFetch } from "../auth"

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 10,
            marginBottom: 10,
          },
        display: "block"
        },
    btn: {
        fontSize: 20,
        width: '100%',
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }
})

const LoggedOutProfile=()=>{
    return (
        <h1>You are not logged in, redirect to login page please</h1>
    )
}


const LoggedInProfile=()=>{
    const classes = useStyles()
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
    const [editProfileOpen,setEditProfileOpen]=useState(false)
    const [showDanger,setShowDanger]=useState(false)
    const [serverResponse,setServerResponse]=useState('')
    const [picture, setPicture] = useState(null)
    const [editProfilePictureOpen,setEditProfilePictureOpen]=useState(false)
    const [userInfo,setUserInfo]=useState({picture_location: 'default.jpg',username: 'Error'});

    useEffect(()=> {
        const token=localStorage.getItem('REACT_TOKEN_AUTH_KEY')

        const requestOptions = {
            method: "GET",
            headers: {
                "Accept": "application/json",
                'Authorization': `Bearer ${JSON.parse(token).access_token}`
            },
        }
        fetch('/api/auth/profile', requestOptions)
        .then(res=> res.json())
        .then(data => {
            data.src = process.env.PUBLIC_URL + '/img' + data.picture_location
            data.member_since = new Date(data.created_at).toLocaleString('en-US',  {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            setUserInfo(data)
        })
        .catch(err=>console.log(err))
    },[]);


    const handleEditProfileOpen = () => {
        setEditProfileOpen(true);
    };

    const handleEditProfileClose = () => {
        setEditProfileOpen(false);
    };

    const handleEditProfilePictureOpen = () => {
        setEditProfilePictureOpen(true);
    };

    const handleEditProfilePictureClose = () => {
        setEditProfilePictureOpen(false);
    };

    const handleFileChange=e=>{
        // setPicture(e.target.files[0])
        setPicture(e.target.files[0]);
    }

    const EditProfile = (data) => {
        setEditProfileOpen(false);
        console.log(data)
        const body = {
                username: data.username,
                email: data.email
            }


        console.log(body)
        const requestOptions = {
            method: "PUT",
            headers: {
                'content-type': "application/json",
            },
            body: JSON.stringify(body)
        }
        authFetch('/api/auth/profile', requestOptions)
        .then(function(res) {
            if (res.status === 404) {
                setShowDanger(true)
                throw new Error('The username or email is already used for another account');
            } else if (res.status !== 200) {
                setShowDanger(true)
                throw new Error('We are having trouble updating your profile info');
            } else {
                setShowDanger(false)
                return res.json();
            }
        })
        .then(data =>{
            if (data.msg) {
                    throw new Error(data.msg)
            } else {
                setShowDanger(false)
                logout()
                login(data)
            }
            updateUser()
        })
        .catch(err => {
            setShowDanger(true)
            setServerResponse(err + ', please try again later.')
        })
    }

    const EditProfilePicture = (data) => {
        // const storageRef="/home/jbrackett/invest/investment/client/public/"

        const body = new FormData()
        body.append("picture_name", picture)
        console.log(body.get('picture_name'))

        setEditProfilePictureOpen(false);
        const requestOptions = {
            method: "PUT",
            headers: {
                // 'content-type': 'multipart/form-data',
                // "Accept": "application/json",
                "type": "formData",
            },
            body: body
        }
        authFetch('/api/auth/profile/picture', requestOptions)
        .then(function(res) {
            if (res.status !== 201) {
                throw new Error('We are having trouble updating your profile picture, try a different one');
            } else if (res.status === 404) {
                setShowDanger(true)
                throw new Error('We are having trouble loading your image');
            } else {
                return res.json();
            }
        })
        .then(data =>{
            console.log(data)
            setServerResponse('Your profile picture was updated')
            setShowDanger(false)
            data.src = process.env.PUBLIC_URL + '/img' + data.picture_location
            data.created_at_2 = new Date(data.created_at)
            data.member_since = data.created_at_2.toLocaleString('en-US',  {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            console.log(data)
            setUserInfo(data)
        })
        .catch(err => {
            setShowDanger(true)
            setServerResponse(err)
        })

    }

    const updateUser = () => {
        const token=localStorage.getItem('REACT_TOKEN_AUTH_KEY')

        const requestOptions = {
            method: "GET",
            headers: {
                "Accept": "application/json",
                'Authorization': `Bearer ${JSON.parse(token).access_token}`
            },
        }
        fetch('/api/auth/profile', requestOptions)
        .then(res=> res.json())
        .then(data => {
            data.src = process.env.PUBLIC_URL + '/img' + data.picture_location
            data.member_since = new Date(data.created_at).toLocaleString('en-US',  {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            setUserInfo(data)
        })
        .catch(err=>console.log(err))
    }

    return (
        <Grid
      >
        <Paper elevation={5} className="App-paper">
            
            <Grid 
                // container
                // direction="row-reverse"
                justifycontent="center"
                alignitems="center"
            >     
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                        <IconButton 
                            id="menu-appbar-md" 
                            onClick={handleEditProfilePictureOpen} 
                            fontSize='medium'
                            sx={{ bgcolor: 'white' }}
                        >   
                            <BrushRoundedIcon fontSize='medium'/>
                        </IconButton>    
                        }
                >
                    <Avatar
                        alt={userInfo.username.toUpperCase()}
                        src={userInfo.src} 
                        sx={{ 
                            width: 255, 
                            height: 255,
                            mt: 2
                        }}
                    />
                </Badge>
                <Dialog
                    maxWidth='xs'
                    fullWidth
                    open={editProfilePictureOpen} 
                    onClose={handleEditProfilePictureClose}
                    size="large"
                >
                    <DialogTitle>Update Profile Picture</DialogTitle>
                    <DialogContent>
                    <TextField
                        autoFocus
                        id="picture"
                        type="file"
                        accept=".png,.jpg"
                        fullWidth
                        variant="outlined"
                        onChange={handleFileChange}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button 
                        onClick={handleEditProfilePictureClose}
                        className={classes.btn}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.btn}
                        onClick={EditProfilePicture}
                    >
                        Update
                    </Button>
                    </DialogActions>
                </Dialog>
                {showDanger?
                    <Box>
                        <br></br>
                        <Alert variant="outlined" severity='warning' onClose={() => setShowDanger(false)} dismissible>
                            {serverResponse}
                        </Alert>
                    </Box>
                    :
                    ''
                }
            </Grid>
            <Container 
                // container="true"
                direction="column"
                justifycontent="flex-start"
                alignitems="center"
            >
                <Typography 
                    variant="overline"
                    display="block"
                    sx={{ mt: 2 }}
                    gutterBottom
                >
                    Username: {userInfo.username}
                </Typography>
                <Typography  variant="overline" display="block" gutterBottom>
                    Email: {userInfo.email}
                </Typography>
                <Typography  variant="overline" display="block" gutterBottom>
                    Stakeholder since: {userInfo.member_since}
                </Typography>
                <Button 
                    className={classes.btn}
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleEditProfileOpen}
                >
                    Edit Profile
                </Button>
                <Dialog 
                    open={editProfileOpen}
                    onClose={handleEditProfileClose}
                    maxWidth='xs'
                    fullWidth
                >
                    <DialogTitle>Update Profile Info</DialogTitle>
                   <form 
                        noValidate 
                        autoComplete="off"
                    >
                    <DialogContent>
                        <FormGroup
                            className={classes.errorRoot}
                        >
                            <TextField
                                type="text"
                                {...register("username", { required: true, maxLength: 25 })}
                                className={classes.field}
                                label="Username"
                                required
                                defaultValue={userInfo.username}
                                error={errors.username}
                            />
                            <div
                                className={classes.errorMsg}
                            >
                                {errors.username?.type === "required" && <p style={{ color: "red" }}><small>Username is required</small></p>}
                                {errors.username?.type === "maxLength" && <p style={{ color: "red" }}><small>Username must be less than 25 characters</small></p>}
                            </div> 
                            
                        </FormGroup>
                        <FormGroup
                            className={classes.errorRoot}
                        >
                            <TextField
                                type="email"
                                className={classes.field}
                                {...register("email", { required: true, maxLength: 25 })}
                                label="Email"
                                variant="outlined"
                                defaultValue={userInfo.email}
                                required
                                error={errors.email}
                            />
                            <div
                                className={classes.errorMsg}
                            >
                                {errors.email?.type === "required" && <p style={{ color: "red" }}><small>Email is required</small></p>}
                                {errors.email?.type === "maxLength" && <p style={{ color: "red" }}><small>Email must be less than 25 characters</small></p>}
                            </div> 
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={handleEditProfileClose}
                            className={classes.btn}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="contained"
                            onClick={handleSubmit(EditProfile)}
                            className={classes.btn}
                        >
                            Update
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>
            </Container>
        </Paper>
    </Grid>
    )
    }


const ProfilePage = () => {
    const roles = useRoles()
    const [logged]=useAuth()

    return (
        <div className="home container">
            {logged?<LoggedInProfile/>:<LoggedOutProfile/>}

            {(logged && (roles.isAdmin))?
            <div>
                <TasksTool catagory="/profile"/>
            </div>
            :
                ""
            }
        </div>
    )
}


export default ProfilePage