import { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import { useAuth, authFetch } from '../auth'
import { Grid } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

// import CompanyThumbnail from './company/CompanyThumbnail'
// import CardGroup from 'react-bootstrap/CardGroup'

// import { Modal ,Form,Button, ModalBody} from 'react-bootstrap'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  navlogolg: {
      color: '#9BCDB0',
      width: '100%',
      marginRight: '.5em',
  },
  navlogosm: {
    color: '#9BCDB0',
    width: '70px',
    marginRight: '.5em',
},
})

const Selections = ({setSelection, attribute_id, images, id,name_code}) => {
    const [value, setValue] = useState(id);
    const [selections, setSelections] = useState([])
    const classes = useStyles()

    useEffect(()=> {
        const data = {
            attribute: attribute_id
        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body:JSON.stringify(data)
        }
    
        fetch(`/api/company/selections`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            setSelections(data)
        })
        .catch(err=>console.log(err))
      },[]);

    const handleChange = (event) => {
        setValue(event.target.value);
        setSelection(event.target.value)
      };


    const [logged]=useAuth()

    return (
            <RadioGroup
                row
                aria-labelledby={{name_code} + '-label'}
                name={name_code}
                value={value}
                defaultValue={id}
                onChange={handleChange}
            >
                {
                    selections.map(
                        (selection,index)=>(
                            <Grid item >
                                <FormControlLabel value={selection.id} control={<Radio />} label={selection.name_view}/>
                                {images ?
                                    <Box sx={{width: 'auto',height: 'auto',alignSelf: 'center'}}>
                                        <img src={process.env.PUBLIC_URL + '/img/' + selection.picture_location} className={classes.navlogosm}/>
                                    </Box>
                                :
                                    ""
                                }
                                
                            </Grid>
                        )
                    )
                }
            </RadioGroup>
                
    )
}


export default Selections