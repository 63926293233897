import { useEffect, useState } from 'react'
import TasksTool from '../admin/Tasks'
import Company from './Company'
import Detail from './Detail'
import { useAuth, authFetch } from '../auth'
import useRoles from '../auth/useRoles'
import { Grid } from '@mui/material';
import { Container } from '@mui/system'


const CompanyPage = ({company,SelectCompany}) => {
    const [companies,setCompanies]=useState([]);
    const [details,setDetails]=useState([]);
    const roles = useRoles()

    useEffect(()=> {
        if (company != window.location.href.toString().split('/')[4]){
            SelectCompany(window.location.href.toString().split('/')[4])
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        fetch(`/api/company/details/${window.location.href.toString().split('/')[4]}`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            setDetails(data)
        })
        .catch(err=>console.log(err))
    },[company]);

    const [logged]=useAuth()

    return (
        <Container>
            <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    
                    {
                        
                        details.map(
                            (detail,index)=>(
                                <Grid item xs={12}>
                                    <Detail key={index}
                                        portfolio={detail.portfolio}
                                        title={detail.title}
                                        description={detail.details}
                                        company={company}
                                    />
                                </Grid>
                            )
                        )
                    }
                        
                </Grid>
            <br></br>
            {(logged && (roles.isAdmin))?
            <div>
                <TasksTool catagory="/company"/>
            </div>
            :
                ""
                }
            {(logged && (roles.isAdmin))?
            <div>
                list of influencers
            </div>
            :
                ""
                }
        </Container>
    )
}


export default CompanyPage