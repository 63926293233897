import { useEffect, useState } from 'react'
import { useAuth, authFetch } from '../auth'
import { Paper, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Order from './Order'


const LoggedInCart=(company)=>{
    const [orders,setOrders]=useState([]);
    const storedValue = localStorage.getItem('Company');

    useEffect(()=> {
        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        authFetch(`/api/order/company/${window.location.href.toString().split('/')[4]}`, requestOptions)
            .then(res=> res.json())
            .then(data => {
                setOrders(data)
            })
            .catch(err=>console.log(err))
},[]);

    const getOrders = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        authFetch(`/api/order/company/${window.location.href.toString().split('/')[4]}`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            setOrders(data)
        })
        .catch(err=>console.log(err))
    }

    return (
        <Paper elevation={5} className="App-paper-full">
            <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    <Grid item xs={12}>
                    <Typography variant="h3" textAlign="center">Orders</Typography>
                    </Grid>
            <Grid item xs={3} display="flex" alignItems={"center"}>
                <Typography textAlign="center">Status</Typography>
            </Grid> 
            <Grid item xs={3} sm={5} display="flex" alignItems={"center"}>
                <Typography textAlign="center">Total</Typography>
            </Grid>
            <Grid item xs={6} sm={4} sx={{ paddingLeft: '1em' }} display="flex" alignItems={"center"}>
                <Typography textAlign="center">Description</Typography>
            </Grid> 
                    
                    {
                        
                        orders.map(
                            (order,index)=>(
                                <Grid item xs={12}>
                                    <Order key={index}
                                        id={order.id}
                                        picture={order.picture}
                                        description={order.description}
                                        quantity={order.quantity}
                                        raw_price={order.raw_price}
                                        stat={order.status}
                                    />
                                </Grid>
                            )
                        )
                    }
                        
                </Grid>
        </Paper>
    )
}

const OrdersPage = (company) => {
    
    const [logged]=useAuth()

    return (
        <div>
            {logged?
                <LoggedInCart company={company}/>
            :
                <Paper elevation={5} className="App-paper-full">
                    Please Login to access this page
                </Paper>
            }
        </div>
    )
}


export default OrdersPage