import './App.css';
import { useEffect, useState } from 'react'
import HideAppBar from './components/NavBarSmall';
import CompanyBar from './components/company/CompanyBar';
import StakeholderBar from './components/stakeholder/StakeholderBar';
import FootBar from './components/FootBar';
import { Routes, Route } from "react-router-dom"
import { BrowserRouter as Router } from 'react-router-dom'
import HomePage from './components/Home';
import Box from '@mui/material/Box';
import ProfilePage from './components/auth/Profile';
import SignUpPage from './components/auth/SignUp';
import LoginPage from './components/auth/Login';
import ForgotPage from './components/auth/Forgot';
import CompanyPage from './components/company/CompanyPage';
import AboutPage from './components/AboutPage';
import NewCompanyPage from './components/company/NewCompanyPage';
import WishUpdatePage from './components/orders/WishUpdatePage';
import OrdersPage from './components/orders/OrdersPage';
import CartPage from './components/orders/CartPage';
import CheckoutPage from './components/orders/CheckoutPage';
import QRPage from './components/company/QRPage';
import BuyPage from './components/company/BuyPage';
import ReviewPage from './components/company/ReviewPage';
import ResetPwdPage from './components/auth/ResetPwd';
import InfluencerPage from './components/stakeholder/InfluencerPage';
import Influencer from './components/stakeholder/Influencer';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  // more found here: https://mui.com/material-ui/customization/default-theme/#main-content
  palette: {
    primary: {
      main: '#13271C',
    },
    secondary: {
      main: '#336749',
    },

  }
})

function App() {
  const [company,setCompany]=useState('brackettinnovations');
  const [primaryColor,setPrimaryColor]=useState('#366C4D');
  const [update,setUpdate]=useState(false)
  const [secondaryColor,setSecondaryColor]=useState('#366C4D');
  const [showBadges,setShowBadges]=useState(false);

  useEffect(()=> {
    const requestOptions = {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
      }
   }
    fetch(`/api/company/bar/${company}`, requestOptions)
    .then(res=> res.json())
    .then(data => {
        setPrimaryColor(data.primary_color)
        setSecondaryColor(data.secondary_color)
    })
    .catch(err=>console.log(err))
  },[]);

  const updateCompany = (comp) => {
    setCompany(comp)
    const requestOptions = {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
      }
   }
    fetch(`/api/company/bar/${comp}`, requestOptions)
    .then(res=> res.json())
    .then(data => {
        setPrimaryColor(data.primary_color)
        setSecondaryColor(data.secondary_color)
    })
    .catch(err=>console.log(err))
    setShowBadges(false)
  }

  const newBadge = () => {
    setUpdate(!update)
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Router>
          <Box className="App" sx={{ backgroundColor: primaryColor }}>
            {company==='brackettinnovations' ?
                <HideAppBar badgeSwitch={() =>{setShowBadges(!showBadges)}} update={update}/>
               :
                <CompanyBar company={company} badgeSwitch={() =>{setShowBadges(!showBadges)}} update={update}/>
            }
            <StakeholderBar company={company} show={showBadges} update={update}/>
            <Routes>
              <Route path="/" 
                element={<HomePage SelectCompany={(company)=>{updateCompany(company)}}/>} 
                
              />
              <Route path="/auth/login" element={<LoginPage/>} />
              <Route path="/auth/signup" element={<SignUpPage/>} />
              <Route path="/auth/forgot" element={<ForgotPage/>} />
              <Route path="/auth/reset" element={<ResetPwdPage/>} />
              <Route path="/auth/profile" element={<ProfilePage/>} />
              <Route path="/cart" element={<CartPage/>} />
              <Route path="/checkout" element={<CheckoutPage badgeView={() =>{setShowBadges(true)}}/>} />
              <Route path="/about" element={<AboutPage/>} />
              <Route path="/new/company" element={<NewCompanyPage newBadge={()=>{newBadge()}}/>} />
              <Route path="/company/:company" element={<CompanyPage company={company} SelectCompany={(company)=>{setCompany(company); setShowBadges(false)}}/>} />
              <Route path="/company/:company/buy" element={<BuyPage company={company} SelectCompany={(company)=>{setCompany(company); setShowBadges(false)}}/>} />
              <Route path="/company/:company/review" element={<ReviewPage company={company} />} />
              <Route path="/company/:company/qr" element={<QRPage SelectCompany={(company)=>{setCompany(company); setShowBadges(false)}}/>} />
              <Route path="/company/:company/order/:id" element={<WishUpdatePage company={company} SelectCompany={(company)=>{setCompany(company); setShowBadges(false)}}/>} />
              <Route path="/company/:company/orders" element={<OrdersPage company={company} SelectCompany={(company)=>{setCompany(company); setShowBadges(false)}}/>} />
              <Route path="/influencer" element={<InfluencerPage SelectCompany={(company)=>{setCompany(company); setShowBadges(false)}} />} />
              <Route path="/influencer/:id" element={<Influencer SelectCompany={(company)=>{updateCompany(company)}} update={()=>{newBadge()}} />} />
            </Routes>
            {company==='brackettinnovations' ?
              <Box className="App-footer">
                <FootBar company="Brackett Innovation"/>
              </Box>
            :
            <Box className="App-footer">
              <FootBar company={company}/>
            </Box>
            }
          </Box>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
