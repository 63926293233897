import React from 'react'
import { Paper, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import NewCompanyInputA from './NewCompanyInputA';
import { login, logout, useAuth } from '../auth'
import { authFetch } from "../auth"

const initialValues = {
  title: '',
  name_view: '',
  short_description: ''
};

const LoggedOutNewCompany=()=>{
    return (
        <h1>You are not logged in, redirect to login page please</h1>
    )
}


const LoggedInNewCompany=({newBadge})=>{

    return (
      <Grid 
          justifycontent="center"
          alignitems="center"
      >
        <Grid item xs={12}>
          <Paper elevation={5} className="App-paper-full">
            <Typography align="left" variant="body1">
                This is the first step to add a company to Brackett Innovations product list. If the company is unactive for a long period of time or 
                does not meet the requirements for a Brackett Innovations business, it may be removed from the database without any warning.
            </Typography>
            <Typography align="left" variant="body1">
                Who may add company to Brackett Innovations? 
            </Typography>
            <ul>
              <li>
                A business owner or employee that wants to utilize Brackett Innovations benifits.
              </li>
              <li>
                An influencer that enjoys the product and wants to influence sales for profits.
              </li>
            </ul>
            <Typography align="left" variant="body1">
                Requirements to add a company to Brackett Innovations:
            </Typography>
            <ul>
              <li>
                The price of each product must be the same as the product found on the official business website.
              </li>
              <li>
                The business should have a defined finders fee to pay each influencer that encourages the sale.
              </li>
            </ul>
          </Paper>
          <Paper elevation={5} className="App-paper-full">
            <NewCompanyInputA initialValues={initialValues} newBadge={()=>{newBadge()}}/>
          </Paper>
        </Grid>
      </Grid>  
    )
    }


const NewCompanyPage = ({newBadge}) => {
    const [logged]=useAuth()

    return (
        <div className="home container">
            {logged?<LoggedInNewCompany newBadge={()=>{newBadge()}}/>:<LoggedOutNewCompany/>}
        </div>
    )
}


export default NewCompanyPage