import { useEffect, useState } from 'react'
import { authFetch } from '../auth'
import { Paper, Grid } from '@mui/material';
import Selections from '../company/Selections'
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


// import CompanyThumbnail from './company/CompanyThumbnail'
// import CardGroup from 'react-bootstrap/CardGroup'

// import { Modal ,Form,Button, ModalBody} from 'react-bootstrap'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block"
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
})

const WishUpdatePage = ({company, SelectCompany}) => {
    const [price,setPrice]=useState(null);
    const [gift, setGift] = useState({"picture_location": '/default.jpg',
                                      "price":50,
                                      "id":0,
                                      "description":"default"
                                    });
    const [product, setProduct] = useState({"picture_location": '/default.jpg',
                                            "price":100000,
                                            "id":0,
                                            "description":"Default Product"
                                          });
    const [selectionsCombo, setSelectionsCombo] = useState([]);
    const [attributes, setAttributes] = useState([])
    const [showGift, setShowGift] = useState(false)
    const [giftOption, setGiftOption] = useState("no")
    const [showDanger,setShowDanger]=useState(false)
    const [show,setShow]=useState(true)
    const [serverResponse,setServerResponse]=useState('Processing...')
    const [openBuy, setOpenBuy] = useState(false);
    const [quantity, setQuantity] = useState(1)
    const [influencer, setInfluencer] = useState('bracketteng')
    const classes = useStyles()

    useEffect(()=> {
        if (company !== window.location.href.toString().split('/')[4]){
            SelectCompany(window.location.href.toString().split('/')[4])
        }
        

        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        authFetch(`/api/order/${window.location.href.toString().split('/')[6]}/wish`, requestOptions)
        .then(res=> res.json())
        .then(data3 => {
            setSelectionsCombo(data3.selections)
            setQuantity(data3.quantity)
            if (data3.gift_set) {
                setGift(true)
                setShowGift(true)
                setGiftOption("yes")
            }
            fetch(`/api/company/attribute/${window.location.href.toString().split('/')[4]}`, requestOptions)
            .then(res=> res.json())
            .then(data => {
                for (let i in data) {
                    data[i].def=data3.selections[i]
                }
                setAttributes(data)
            })
            .catch(err=>console.log(err))
            
            const body = {
                selections: data3.selections,
            }


            const requestOptions2 = {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(body)
            }
            fetch('/api/company/products', requestOptions2)
            .then(res=> res.json())
            .then(data2 => {
                setProduct(data2)
                fetch(`/api/company/gift/${window.location.href.toString().split('/')[4]}`, requestOptions)
                .then(res=> res.json())
                .then(data => {
                    setGift(data)
                    if (data3.gift_set) {
                            setPrice((data2.price_usd+data.price_usd)*data3.quantity)
                            console.log(data2)
                        } else {
                            setPrice(data2.price_usd*data3.quantity)
                        }
                })
                .catch(err=>console.log(err))
                
                
            })
            .catch(err=>console.log(err))
            console.log(data3)
        })
        .catch(err=>console.log(err))

      },[company]);

    const handleChange = (index, value) => {
        console.log(index)
        console.log(value)
        let newArr = [...selectionsCombo]
        newArr[index]=parseInt(value)
        setSelectionsCombo(newArr)
        console.log(newArr)
        const body = {
            selections: newArr,
        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(body)
        }
        fetch('/api/company/products', requestOptions)
        .then(res=> res.json())
        .then(data => {
            setProduct(data)
            if (showGift) {
                setPrice((data.price_usd+gift.price_usd)*quantity);
            } else {
                setPrice((data.price_usd)*quantity);
            }
            console.log(data)
        })
        .catch(err=>console.log(err))
      };

    const handleSubmit = (event) => {
        
        setOpenBuy(true)
        const body = {
            product_id: product.id,
            quantity: quantity,
            gift_set: showGift,
            gift_id: gift.id
        }
        console.log(body)
        const requestOptions = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(body)
        }
        authFetch(`/api/order/${window.location.href.toString().split('/')[6]}/wish/update`, requestOptions)
        .then(function(res) {
            if (res.status === 201) {
                setShow(true)
                setShowDanger(false)
                return res.json();
            } else {
                setServerResponse('else')
                setShowDanger(true)
                setShow(false)
                throw new Error('Invalid input');
            }
        })
        .then(data => {
            console.log(data)
            setServerResponse('This order has been placed in your cart.')
        })
        .catch(err=>{
            console.log('why')
            setShowDanger(true)
            setShow(false)
            setServerResponse(err + ', please try again later.')
        })
        event.preventDefault()
    }



    const updateQuantity = (event) => {
        if (event.target.value > 0 || event.target.value === '') {
            setQuantity(event.target.value)
            if (showGift) {
                setPrice((product.price_usd+gift.price_usd)*event.target.value);
            } else {
                setPrice((product.price_usd)*event.target.value);
            }
        }
      };

    const updateGift = (event) => {
        if (event.target.value==='yes') {
            setShowGift(true)
            setPrice((product.price_usd+gift.price_usd)*quantity);
        } else {
            setShowGift(false)
            setPrice((product.price_usd)*quantity);
        }
        setGiftOption(event.target.value)
        console.log(showGift)
      };

    const handleClose = () => {
        setOpenBuy(false);
    };

    return (
        <Paper elevation={5} className="App-paper-full">
            <form>               
                <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="influencer-label">Influencer</FormLabel>
                            <TextField
                                type="text"
                                className={classes.field}
                                label="Influencer"
                                variant="outlined"
                                name="influencer"
                                onChange={(event)=>setInfluencer(event.target.value)}
                                value={influencer}
                            />
                        </FormControl>
                        
                    </Grid>
                    {
                        attributes.map(
                            (attribute,index)=>(
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id={attribute.name_code}>{attribute.name_view}</FormLabel>
                                        <Selections 
                                            attribute_id={attribute.id} 
                                            images={attribute.picture} 
                                            setSelection={(value)=>{handleChange(index,value)}}
                                            company={company}
                                            name_code={attribute.name_code}  
                                            id={attribute.def}   
                                        />
                                    </FormControl>
                                    
                                </Grid>
                            )
                        )
                    }
                
                    <Grid item xs={12} sx={{alignSelf: 'center'}}>
                        
                        <FormControl>
                            <FormLabel id="radio-gift-label">Gift Wrapped</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="radio-gift"
                                    name="gift-radio"
                                    defaultValue='no'
                                    value={giftOption}
                                    onChange={updateGift}
                                >
                                    <FormControlLabel value='yes' control={<Radio />} label='Yes'/>
                                    <FormControlLabel value='no' control={<Radio />} label='No'/>
                                </RadioGroup>
                        </FormControl>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="quantity-label">Quantity</FormLabel>
                            <TextField
                                type="number"
                                className={classes.field}
                                variant="outlined"
                                name="quantity"
                                defaultValue="1"
                                value={quantity}
                                onChange={updateQuantity}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel id="radio-gift">Final Product</FormLabel>
                    </Grid>     
                    <Grid item xs={12} display="flex" alignItems={"center"}>
                        <Box>
                            <img 
                                src={process.env.PUBLIC_URL + '/img' + product.picture_location} 
                                className={classes.productimage}
                            />
                        </Box>
                        {showGift?
                            <Box disply='flex'>
                                <img 
                                    src={process.env.PUBLIC_URL + '/img' + gift.picture_location} 
                                    className={classes.productimage}
                                />
                            </Box>
                        :
                            ""
                        }
                        
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            {product.description}
                        </Box>
                        {showGift?
                            <Box disply='flex'>
                                {gift.description}
                            </Box>
                        :
                            ""
                        }
                        
                    </Grid> 
                    <Grid item xs={12}>
                        <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined" onClick={handleSubmit}>
                            Total: ${price}
                        </Button>
                    </Grid>   
                    <br></br>
                </Grid>
                <Dialog
                    open={openBuy}
                    onClose={handleClose}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">
                    {"Order Product"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-dialog-description">
                            {show?
                                <Box>
                                <Alert variant="outlined" severity='success' onClose={() => setShow(false)}>
                                        {serverResponse}
                                    </Alert>
                                    {/* <small><Link to='/'>Navigate to Products</Link></small> */}
                                </Box>

                                
                                :
                                ""
                            }
                            {showDanger?
                                <Box>
                                    <Alert variant="outlined" severity='warning' onClose={() => setShowDanger(false)} dismissible>
                                        {serverResponse}
                                    </Alert>
                                </Box>
                                :
                                ''
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Link to='/cart'>
                        <Button onClick={handleClose} autoFocus>
                            Checkout
                        </Button>
                    </Link>
                    
                    </DialogActions>
                </Dialog>
            </form>
        </Paper>
    )
}


export default WishUpdatePage