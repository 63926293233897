import { useEffect, useState, useRef } from 'react'
import TasksTool from '../admin/Tasks'
import Company from '../company/Company'
// import { Link } from 'react-router-dom'
import { useAuth, authFetch } from '../auth'
import useRoles from '../auth/useRoles'
import { Paper, Grid } from '@mui/material';
import Selections from './Selections'
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import { useLocation } from "react-router-dom";
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Wish from '../orders/Wish';
import { Link } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


// import CompanyThumbnail from './company/CompanyThumbnail'
// import CardGroup from 'react-bootstrap/CardGroup'

// import { Modal ,Form,Button, ModalBody} from 'react-bootstrap'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block"
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
})

const BuyPage = ({company, SelectCompany}) => {
    const [price,setPrice]=useState(null);
    const [gift, setGift] = useState({"picture_location": '/default.jpg',
                                      "price":50,
                                      "id":0,
                                      "description":"default"
                                    });
    const [product, setProduct] = useState({"picture_location": '/default.jpg',
                                            "price":100000,
                                            "id":0,
                                            "description":"Default Product"
                                          });
    const [selectionsCombo, setSelectionsCombo] = useState([]);
    const [attributes, setAttributes] = useState([])
    const [showGift, setShowGift] = useState(false)
    const roles = useRoles()
    const [showDanger,setShowDanger]=useState(false)
    const [show,setShow]=useState(true)
    const [serverResponse,setServerResponse]=useState('Processing...')
    const [openBuy, setOpenBuy] = useState(false);
    const [quantity, setQuantity] = useState(1)
    const [influencer, setInfluencer] = useState('bracketteng')
    const classes = useStyles()
    const [logged]=useAuth()

    useEffect(()=> {
        if (company != window.location.href.toString().split('/')[4]){
            SelectCompany(window.location.href.toString().split('/')[4])
        }
        let storedValue = localStorage.getItem('Influencer');

        const requestOptions = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        if (!storedValue) {
            localStorage.setItem('Influencer', '1');
            storedValue='1'
        } 
        fetch(`/api/influencer/${storedValue}`, requestOptions)
        .then(function(res) {
            if (res.status === 200) {
                return res.json();
            } else {
                throw new Error()
            }
        })
        .then(data => {
            setInfluencer(data.username)
        })
        .catch(err=>console.log(err))
    
        fetch(`/api/company/attribute/${window.location.href.toString().split('/')[4]}`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            setAttributes(data)
            let newArr = []
            for (let i in data) {
                newArr[i]=data[i].default_selections_id
            }
            setSelectionsCombo(newArr)
            const body = {
                selections: newArr,
            }
            const requestOptions2 = {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(body)
            }
            fetch('/api/company/products', requestOptions2)
            .then(res=> res.json())
            .then(data2 => {
                setProduct(data2)
                setPrice(data2.price_usd)
            })
            .catch(err=>console.log(err))
        })
        .catch(err=>console.log(err))

        fetch(`/api/company/gift/${window.location.href.toString().split('/')[4]}`, requestOptions)
        .then(res=> res.json())
        .then(data => {
            setGift(data)
            // setProduct(data.product_location)
            // setProductPrice(data.product_price)
            // setPrice(data.product_price)
            
        })
        .catch(err=>console.log(err))
      },[company]);

    const handleChange = (index, value) => {
        let newArr = [...selectionsCombo]
        newArr[index]=parseInt(value)
        setSelectionsCombo(newArr)
        console.log(newArr)
        const body = {
            selections: newArr,
        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(body)
        }
        fetch('/api/company/products', requestOptions)
        .then(res=> res.json())
        .then(data => {
            setProduct(data)
            if (showGift) {
                setPrice((data.price_usd+gift.price_usd)*quantity);
            } else {
                setPrice((data.price_usd)*quantity);
            }
            console.log(data)
        })
        .catch(err=>console.log(err))
      };

    const handleSubmit = (event) => {
        
        setOpenBuy(true)

        if (logged) {
        const body = {
            product_id: product.id,
            gift: showGift,
            gift_id: gift.id,
            influencer: localStorage.getItem('Influencer'),
            quantity: quantity,
            company: company
        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(body)
        }
        authFetch('/api/order/wish', requestOptions)
        .then(function(res) {
            if (res.status === 200) {
                setShow(true)
                setShowDanger(false)
                return res.json();
            } else {
                setServerResponse('else')
                setShowDanger(true)
                setShow(false)
                throw new Error('Invalid input');
            }
        })
        .then(data => {
            console.log(data)
            setServerResponse('This order has been placed in your cart.')
        })
        .catch(err=>{
            console.log('why')
            setShowDanger(true)
            setShow(false)
            setServerResponse(err + ', please try again later.')
        })

        } else {
            setShowDanger(true)
            setShow(false)
        }
        event.preventDefault()
    }



    const updateQuantity = (event) => {
        if (event.target.value > 0 || event.target.value == '') {
            setQuantity(event.target.value)
            if (showGift) {
                setPrice((product.price_usd+gift.price_usd)*event.target.value);
            } else {
                setPrice((product.price_usd)*event.target.value);
            }
        }
      };

    const updateGift = (event) => {
        if (event.target.value==='yes') {
            setShowGift(true)
            setPrice((product.price_usd+gift.price_usd)*quantity);
        } else {
            setShowGift(false)
            setPrice((product.price_usd)*quantity);
        }
        console.log(showGift)
      };

    const handleClose = () => {
        setOpenBuy(false);
    };

    

    return (
        <Paper elevation={5} className="App-paper-full">
            <form>               
                <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    <Grid item xs={12}>
                        <Typography textAlign="center" variant="subtitle">Influencer: {influencer}</Typography>  
                    </Grid>
                    <br></br>
                    {
                        attributes.map(
                            (attribute,index)=>(
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id={attribute.name_code}>{attribute.name_view}</FormLabel>
                                        <Selections 
                                            attribute_id={attribute.id} 
                                            images={attribute.picture} 
                                            setSelection={(value)=>{handleChange(index,value)}}
                                            company={company}
                                            name_code={attribute.name_code}
                                            id={attribute.default_selections_id}   
                                        />
                                    </FormControl>
                                    
                                </Grid>
                            )
                        )
                    }
                
                    <Grid item xs={12} sx={{alignSelf: 'center'}}>
                        
                        <FormControl>
                            <FormLabel id="radio-gift-label">Gift Wrapped</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="radio-gift"
                                    name="gift-radio"
                                    defaultValue='no'
                                    onChange={updateGift}
                                >
                                    <FormControlLabel value='yes' control={<Radio />} label='Yes'/>
                                    <FormControlLabel value='no' control={<Radio />} label='No'/>
                                </RadioGroup>
                        </FormControl>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="quantity-label">Quantity</FormLabel>
                            <TextField
                                type="number"
                                className={classes.field}
                                variant="outlined"
                                name="quantity"
                                defaultValue="1"
                                value={quantity}
                                onChange={updateQuantity}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel id="radio-gift">Final Product</FormLabel>
                    </Grid>     
                    <Grid item xs={12} display="flex" alignItems={"center"}>
                        <Box>
                            <img 
                                src={process.env.PUBLIC_URL + '/img' + product.picture_location} 
                                className={classes.productimage}
                            />
                        </Box>
                        {showGift?
                            <Box disply='flex'>
                                <img 
                                    src={process.env.PUBLIC_URL + '/img' + gift.picture_location} 
                                    className={classes.productimage}
                                />
                            </Box>
                        :
                            ""
                        }
                        
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            {product.description}
                        </Box>
                        {showGift?
                            <Box disply='flex'>
                                {gift.description}
                            </Box>
                        :
                            ""
                        }
                        
                    </Grid> 
                    <Grid item xs={12}>
                        <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined" onClick={handleSubmit}>
                            Total: ${price}
                        </Button>
                    </Grid>   
                    <br></br>
                </Grid>
                <Dialog
                    open={openBuy}
                    onClose={handleClose}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">
                    {"Order Product"}
                    </DialogTitle>
                    <DialogContent>
                    {logged?
                        <DialogContentText id="delete-dialog-description">
                            {show?
                                <Box>
                                <Alert variant="outlined" severity='success' onClose={() => setShow(false)}>
                                        {serverResponse}
                                    </Alert>
                                </Box>

                                
                                :
                                ""
                            }
                            {showDanger?
                                <Box>
                                    <Alert variant="outlined" severity='warning' onClose={() => setShowDanger(false)} dismissible>
                                        {serverResponse}
                                    </Alert>
                                </Box>
                                :
                                ''
                            }
                        </DialogContentText>
                    :
                        <DialogContentText id="delete-dialog-description">
                            You must log in before you can order this product.
                        </DialogContentText>
                    }
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {logged?
                        <Link to='/cart' style={{ textDecoration: 'none' }}>
                            <Button onClick={handleClose} autoFocus>
                                Checkout
                            </Button>
                        </Link>
                    :
                        <Link to='/auth/login' style={{ textDecoration: 'none' }}>
                            <Button onClick={handleClose} autoFocus>
                                Log In
                            </Button>
                        </Link>
                    }
                    
                    </DialogActions>
                </Dialog>
            </form>
        </Paper>
    )
}


export default BuyPage