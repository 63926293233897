import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
        name: {
            color: (props) => props.primary_color,
            width: '100%',
            marginRight: '.5em',
        },
        navlogosm: {
          color: '#9BCDB0',
          width: '70px',
          marginRight: '.5em',
      },
      })


// const Company = ({name_view,logo,description,title,cover,primary_color,secondary_color}) => {
const Detail = (props) => {
    const {description,title,portfolio,company} = props
    const portfolio_img_path=process.env.PUBLIC_URL + '/img' +  props.portfolio[0]

    const classes=useStyles(props)

    return (
      <Card sx={{ maxWidth: 345 }} className="App-paper">
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={portfolio_img_path}
            alt={company}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
}

export default Detail

